import React from "react";
import "../src/pages/style.css";
import {
  Navigate,
  BrowserRouter as Router,
  Route,
  Routes
} from "react-router-dom";
import agent from "./agent";
import Notification from "./components/Notification";

// Login Import
import Login from "./pages/Login";
// Sign Up Imports
import Signup from "./pages/Signup";
import SignupOTP from "./pages/Verify/SignupOTP";
import SignupToken from "./pages/Verify/SignupToken";
// Forgot Password Imports
import ForgetPassword from "./pages/ForgetPassword";
import ResetOTP from "./pages/Verify/ResetOTP";
import ResetToken from "./pages/Verify/ResetToken";

// Profile Import
import Profile from "./pages/Profile/Index";

// Connect to redux
import { TOKEN_PRESENT, UPDATE_COMMON } from "./store/types";
import { connect, ConnectedProps } from "react-redux";

// Importing Firebase
import { initNotification } from "./firebase";
import ConfirmationPopup from "./components/ConfirmationPopup";
import Maintenance from "./pages/Maintenance";

// Subscription Imports
import Subscription from "./pages/Subscription";
import PaymentResponse from "./pages/Subscription/PaymentReponse";
import { WithRouterProps } from "./helpers/withRouter";
import Invoices from "./pages/Subscription/Invoices";
import NotFound from "./pages/NotFound";
import { AppDispatch, RootState } from "./store";
import { CommonState } from "./store/reducers/common";

// Firm Imports
import Firms from "./pages/Firms";

// Client Imports
import Client from "./pages/Client";
import AddEditPage from "./pages/Client/AddEditPage";
import Import from "./pages/Client/import/index";

// User Imports
import Users from "./pages/Users";
import AddEditViewUser from "./pages/Users/AddEditViewPage";

const mapStateToProps = (state: RootState) => ({
  ...state.user,
  ...state.common
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  updateCommon: (payload: Partial<CommonState>) =>
    dispatch({ type: UPDATE_COMMON, payload }),
  onTokenPresent: (token: string) =>
    dispatch({ type: TOKEN_PRESENT, payload: { token } })
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = Partial<PropsFromRedux & WithRouterProps> & {
  showMaintenance?: boolean;
};

interface State {
  loading: boolean;
  addingEditingTodo: boolean;
  showConfirmationPopup: boolean;
}

class App extends React.Component<Props, State> {
  // Getting Cookies and Set Token from Cookies
  constructor(props: any) {
    super(props);
    this.state = {
      loading: false,
      addingEditingTodo: false,
      showConfirmationPopup: false
    };
  }

  getCookie = (cname: string) => {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) === " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  };

  PrivateRoute = ({ children }: { children: JSX.Element }) => {
    return this.props.isAuthenticated ? children : <Navigate to="/" />;
  };

  PublicRoute = ({ children }: { children: JSX.Element }) => {
    return !this.props.isAuthenticated ? (
      children
    ) : (
      <Navigate to={`/clients/list`} />
    );
  };

  closeModal = (fetchAgain?: boolean) => {
    this.props.updateCommon?.({
      currentModal: { modalName: "", fetchAgain }
    });
  };

  showNotificationConfirmationPopup = () => {
    if (localStorage.getItem("notification")) return;
    setTimeout(() => {
      this.setState({ showConfirmationPopup: true });
    }, 2000);
  };

  onConfirm = () => {
    this.setState({ showConfirmationPopup: false });
    initNotification();
  };

  onCancel = () => {
    this.setState({ showConfirmationPopup: false });
    localStorage.setItem("notification", "default");
  };

  async UNSAFE_componentWillMount() {
    this.setState({ loading: true });
    if (this.getCookie("login-tool-token")) {
      agent.setToken(this.getCookie("login-tool-token"));
      await this.props.onTokenPresent?.("PRESENT");
      this.setState({ loading: false });
    }
    this.setState({ loading: false });
  }

  componentDidMount() {
    document.title = "TaxPido Login Tool";

    // this.showNotificationConfirmationPopup();
  }

  render() {
    return this.props.showMaintenance ? (
      <Router>
        <Maintenance />
      </Router>
    ) : (
      <div>
        <Notification />
        {!this.state.loading ? (
          <Router>
            {this.state.showConfirmationPopup && (
              <ConfirmationPopup
                title="Enable Notifications"
                message="Please enable push notifications to get notified about your tasks."
                confirmText="Enable"
                onConfirm={this.onConfirm}
                cancelText="Cancel"
                onCancel={this.onCancel}
              />
            )}
            <Routes>
              {/* SignUp */}
              <Route
                path="/signup"
                element={
                  <this.PublicRoute>
                    <Signup />
                  </this.PublicRoute>
                }
              />
              {/* Login */}
              <Route
                path="/"
                element={
                  <this.PublicRoute>
                    <Login />
                  </this.PublicRoute>
                }
              />
              <Route
                path="/verify/signup/otp/:id"
                element={
                  <this.PublicRoute>
                    <SignupOTP />
                  </this.PublicRoute>
                }
              />
              <Route
                path="/verify/signup/token/:token"
                element={
                  <this.PublicRoute>
                    <SignupToken />
                  </this.PublicRoute>
                }
              />
              {/* Forgot Password */}
              <Route
                path="/forgetPassword"
                element={
                  <this.PublicRoute>
                    <ForgetPassword />
                  </this.PublicRoute>
                }
              />
              <Route
                path="/verify/reset/otp/:id"
                element={
                  <this.PublicRoute>
                    <ResetOTP />
                  </this.PublicRoute>
                }
              />
              <Route
                path="/verify/reset/token/:token"
                element={
                  <this.PublicRoute>
                    <ResetToken />
                  </this.PublicRoute>
                }
              />
              {/* Firm Page */}
              <Route
                path="/firms"
                element={
                  <this.PrivateRoute>
                    <Firms />
                  </this.PrivateRoute>
                }
              />
              {/* Profile Page */}
              <Route
                path="/profile/:tab"
                element={
                  <this.PrivateRoute>
                    <Profile />
                  </this.PrivateRoute>
                }
              />
              {/* Subscription Page */}
              <Route
                path="/:firmId/subscriptions"
                element={
                  <this.PrivateRoute>
                    <Subscription />
                  </this.PrivateRoute>
                }
              />
              <Route
                path="/paymentresponse"
                element={
                  <this.PrivateRoute>
                    <PaymentResponse />
                  </this.PrivateRoute>
                }
              />
              {/* Invoices Page */}
              <Route
                path="/:firmId/invoices"
                element={
                  <this.PrivateRoute>
                    <Invoices />
                  </this.PrivateRoute>
                }
              />
              {/* Client Page */}

              {/* For not showing 404 page on login */}
              <Route
                path="/clients/list"
                element={
                  <this.PrivateRoute>
                    <Client />
                  </this.PrivateRoute>
                }
              />
              <Route
                path="/:firmId/clients/list"
                element={
                  <this.PrivateRoute>
                    <Client />
                  </this.PrivateRoute>
                }
              />
              <Route
                path="/:firmId/clients/import"
                element={
                  <this.PrivateRoute>
                    <Import />
                  </this.PrivateRoute>
                }
              />
              <Route
                path="/:firmId/clients/:pageType"
                element={
                  <this.PrivateRoute>
                    <AddEditPage />
                  </this.PrivateRoute>
                }
              />
              {/* User Page */}
              <Route
                path="/:firmId/user/list"
                element={
                  <this.PrivateRoute>
                    <Users />
                  </this.PrivateRoute>
                }
              />
              <Route
                path="/:firmId/user/:pageType"
                element={
                  <this.PrivateRoute>
                    <AddEditViewUser />
                  </this.PrivateRoute>
                }
              />

              {/* No Match */}
              <Route
                path="*"
                element={
                  <NotFound loggedIn={this.props.isAuthenticated || false} />
                }
              />
            </Routes>
          </Router>
        ) : null}
      </div>
    );
  }
}

export default connector(App);
