import Dashboard from "../../components/Dashboard";
import { ADD_NOTIFICATION } from "../../store/types";
import TagManager from "react-gtm-module";
import { connect, ConnectedProps } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import { withRouter, WithRouterProps } from "../../helpers/withRouter";
import { Component } from "react";
import agent from "../../agent";
import { formatDate } from "../../helpers/formatDate";
import { compose } from "redux";
import getCommaSeparatedValue from "../../helpers/getCommaSeparatedValue";
import Skeleton from "react-loading-skeleton";
import Button from "../../components/Button";
import { ArrowDownTrayIcon } from "@heroicons/react/24/solid";
import { downloadFile } from "../../helpers/downloadFile";
import Icon from "../../components/Icon";
import { NotificationType } from "../../store/reducers/notification";

const tagManagerArgs = {
  dataLayer: {
    userId: "001",
    userProject: "TaxPido Login Tool",
    page: "Invoices"
  },
  dataLayerName: "PageDataLayer"
};

const mapStateToProps = (state: RootState) => ({
  ...state.notification,
  ...state.common
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  addNotification: (title: string, message: string, type: NotificationType) =>
    dispatch({ type: ADD_NOTIFICATION, payload: { title, message, type } })
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = Partial<PropsFromRedux & WithRouterProps> & {};

export interface Invoice {
  _id: string;
  workSpaceId: string;
  fileName: string;
  path: string;
  orderId: string;
  createdAt: Date;
  updatedAt: Date;
  invoiceNumber: string;
  paymentType: string;
  amount: number;
}

interface State {
  invoices: Invoice[];
  loading: boolean;
  downloading: boolean;
}

class Invoices extends Component<Props, State> {
  state: State = {
    invoices: [],
    loading: false,
    downloading: false
  };

  headers = [
    "Date of Invoices",
    "Invoice Number",
    "Payment Type",
    "Amount",
    "Download"
  ];

  componentDidMount() {
    this.getInvoices();
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (prevProps.params?.firmId !== this.props.params?.firmId) {
      this.getInvoices();
    }
  }

  getInvoices = () => {
    const workSpaceId = this.props.params?.firmId;
    if (workSpaceId) {
      this.setState({ loading: true });
      agent.Payment.getInvoices<{ invoices: Invoice[] }>(workSpaceId)
        .then(response => {
          this.setState({ invoices: response.invoices, loading: false });
        })
        .catch((error: any) => {
          this.setState({ loading: false });
          this.props.addNotification?.(
            "Could not load invoices",
            error?.response?.data?.message || "Something went wrong",
            "danger"
          );
        });
    }
  };

  downloadInvoice = (invoice: Invoice) => {
    const workSpaceId = this.props.params?.firmId;

    if (workSpaceId) {
      this.setState({ downloading: true });
      agent.Payment.downloadInvoice(workSpaceId, invoice._id)
        .then(response => {
          this.setState({ downloading: false });
          downloadFile(
            response,
            `TaxPido Login Tool Invoice ${invoice.invoiceNumber}.pdf`
          );
        })
        .catch(err => {
          this.setState({ downloading: false });
          const data = new Blob([err.response.data], {
            type: err.response.data.type
          });
          console.log("data", data);
          data.text().then(text => {
            const error = JSON.parse(text);
            this.props.addNotification?.(
              "Could not download invoice",
              error?.message || error,
              "danger"
            );
          });
        });
    }
  };

  render() {
    TagManager.dataLayer(tagManagerArgs);
    return (
      <Dashboard>
        <div className="max-w-8xl mx-auto px-4 sm:px-6 md:px-8 space-y-8">
          <h1 className="text-2xl font-semibold text-gray-900 ">Invoices</h1>
          <div className="mt-6">
            <div id="table-scroll" className="overflow-auto">
              <div className="inline-block min-w-full py-2 px-1 align-middle">
                <div className="shadow-sm ring-1 ring-black ring-opacity-5">
                  <table className="min-w-full border-collapse border shadow-sm">
                    <thead className="bg-gray-50">
                      <tr>
                        {this.headers.map(header => (
                          <th
                            key={header}
                            scope="col"
                            className={`whitespace-nowrap border-b font-bold border-gray-300 bg-gray-50 px-6 py-3 text-xs text-gray-500 uppercase tracking-wider ${
                              header.includes("Download")
                                ? "text-center"
                                : "text-left"
                            }`}
                          >
                            {header}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody className="bg-white">
                      {!this.state.loading ? (
                        this.state.invoices.length > 0 ? (
                          this.state.invoices?.map(invoice => (
                            <tr key={invoice._id} className="odd:bg-gray-100">
                              <td className="w-3/12 whitespace-nowrap py-4 px-6 font-bold text-sm text-gray-900">
                                {formatDate(invoice.createdAt, false)}
                              </td>
                              <td className="w-3/10 px-6 py-3 text-sm text-gray-500 relative">
                                {invoice.invoiceNumber}
                              </td>
                              <td className="w-4/10 px-6 py-3 whitespace-nowrap font-bold text-sm text-gray-900">
                                {invoice.paymentType}
                              </td>
                              <td className="w-4/10 px-6 py-3 whitespace-nowrap text-sm text-gray-500">
                                {getCommaSeparatedValue(invoice.amount, true)}
                              </td>
                              <td className="px-6 py-3 mx-4 text-center whitespace-nowrap text-sm text-gray-900">
                                <button
                                  onClick={() => this.downloadInvoice(invoice)}
                                >
                                  {this.state.downloading ? (
                                    <Icon
                                      name="loading"
                                      className="h-5 w-5 text-gray-500"
                                    />
                                  ) : (
                                    <ArrowDownTrayIcon className="h-5 w-5 text-gray-500 hover:text-indigo-700" />
                                  )}
                                </button>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr className="bg-white">
                            <td
                              colSpan={this.headers.length}
                              className="w-3/10 px-6 py-3 whitespace-wrap text-sm font-medium text-gray-900 text-center"
                            >
                              No invoices found
                            </td>
                          </tr>
                        )
                      ) : (
                        [...Array(5)].map((e, i) => (
                          <tr key={i} className="bg-white">
                            {[...Array(this.headers.length)].map((e, i) => (
                              <td
                                key={i}
                                className="w-3/10 px-6 py-3 whitespace-wrap text-sm font-medium text-gray-900"
                              >
                                <Skeleton />
                              </td>
                            ))}
                          </tr>
                        ))
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Dashboard>
    );
  }
}

export default compose(connector, withRouter)(Invoices) as React.ComponentType;
