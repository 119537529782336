import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import config from "./config";
import { PurchaseType } from "./pages/Subscription";
import {
  ClientDetailsToAdd,
  ClientDetailsWithClientId,
  ClientType
} from "./helpers/types";

axios.defaults.baseURL = config.baseURL;

let token: string | undefined;

const responseBody = <TResponse>(res: AxiosResponse<TResponse>) => res.data;

const getHeader = () => {
  if (!token) return {};

  return { headers: { Authorization: `Bearer ${token}` } };
};

const requests = {
  get: <TResponse>(url: string) =>
    axios.get<TResponse>(url, getHeader()).then(responseBody),
  getBlob: (url: string, options: AxiosRequestConfig) =>
    axios.get<Blob>(url, { ...options, ...getHeader() }),
  post: <TResponse>(url: string, body?: any) =>
    axios.post<TResponse>(url, body, getHeader()).then(responseBody),
  put: <TResponse>(url: string, body: any) =>
    axios.put<TResponse>(url, body, getHeader()).then(responseBody),
  patch: <TResponse>(url: string, body: any) =>
    axios.patch<TResponse>(url, body, getHeader()).then(responseBody),
  delete: <TResponse>(url: string, body?: any) =>
    axios
      .delete<TResponse>(url, { headers: getHeader().headers, data: body })
      .then(responseBody)
};

const Auth = {
  //Login
  login: <TResponse>(username: string, password: string) =>
    requests.post<TResponse>("/auth/login", { username, password }),
  //Signup
  signup: <TResponse>(
    name: string,
    email: string,
    password: string,
    mobileNumber: string
  ) =>
    requests.post<TResponse>("/auth/signup", {
      name,
      email,
      password,
      mobileNumber
    }),
  verifySignupOTP: <TResponse>(userId: string, otp: number) =>
    requests.post<TResponse>("/auth/verify/signup/otp", { userId, otp }),
  verifySignupToken: <TResponse>(token: string) =>
    requests.post<TResponse>("/auth/verify/signup/token", { token }),
  //Forgot Password
  forgotPassword: <TResponse>(email: string) =>
    requests.post<TResponse>("/auth/forgotPassword", { email }),
  verifyResetOtp: <TResponse>(userId: string, otp: number, password: string) =>
    requests.post<TResponse>("/auth/verify/reset/otp", {
      userId,
      otp,
      password
    }),
  verifyResetToken: <TResponse>(token: string, password: string) =>
    requests.post<TResponse>("/auth/verify/reset/token", { token, password })
};

const Firm = {
  getFirms: <TResponse>() => requests.get<TResponse>("/workspace/list"),
  addFirm: <TResponse>(name: string) =>
    requests.post<TResponse>("/workspace/add", { name }),
  editFirm: <TResponse>(name: string, workSpaceId: string) =>
    requests.patch<TResponse>("/workspace/updatename", { workSpaceId, name }),
  makeFirmInactive: <TResponse>(workSpaceId: string) =>
    requests.patch<TResponse>("/workspace/inactive", { workSpaceId }),
  makeFirmActive: <TResponse>(workSpaceId: string) =>
    requests.patch<TResponse>("/workspace/active", { workSpaceId }),
  listofInvitationSent: <TResponse>(workSpaceId: string) =>
    requests.get<TResponse>(
      `/workspace/listofinvitationsent?workSpaceId=${workSpaceId}`
    ),
  listofInvitationReceived: <TResponse>() =>
    requests.get<TResponse>(`/workspace/listofinvitationreceived`),
  leaveFirm: <TResponse>(workSpaceId: string) =>
    requests.post<TResponse>("/workspace/leaveworkspace", { workSpaceId }),
  revokeInvitation: <TResponse>(workSpaceId: string, invitationId: string) =>
    requests.post<TResponse>("/workspace/revokeinvitation", {
      workSpaceId,
      invitationId
    }),
  rejectInvitation: <TResponse>(workSpaceId: string, invitationId: string) =>
    requests.post<TResponse>("/workspace/rejecttinvitation", {
      workSpaceId,
      invitationId
    }),
  acceptInvitation: <TResponse>(workSpaceId: string, invitationId: string) =>
    requests.post<TResponse>("/workspace/acceptinvitation", {
      workSpaceId,
      invitationId
    }),
  addUser: <TResponse>(workSpaceId: string, userEmail: string) =>
    requests.post<TResponse>("/workspace/adduser", { workSpaceId, userEmail }),
  deleteFirm: <TResponse>(workSpaceId: string) =>
    requests.delete<TResponse>(
      `/workspace/delete?workSpaceId=${workSpaceId}`,
      {}
    )
};

const User = {
  addFirebaseToken: <TResponse>(
    token: string,
    type: string,
    browser: string,
    device: string
  ) =>
    requests.post<TResponse>("/user/addpushtoken", {
      token,
      type,
      browser,
      device
    }),
  getSelfDetails: <TResponse>(workSpaceId: string) =>
    requests.get<TResponse>(
      `/user/getselfdetailsforworkspace?workSpaceId=${workSpaceId}`
    ),
  getUserList: <TResponse>(
    workSpaceId: string,
    active: boolean,
    searchText: string,
    includeCurrent: boolean
  ) =>
    requests.get<TResponse>(
      `/user/list?workSpaceId=${workSpaceId}&active=${active}&searchText=${encodeURIComponent(
        searchText
      )}&includeCurrent=${includeCurrent}`
    ),
  addUser: <TResponse>(workSpaceId: string, email: string, role: string) =>
    requests.post<TResponse>("workspace/adduser", { workSpaceId, email, role }),

  editUser: <TResponse>(workSpaceId: string, email: string, role: string) =>
    requests.post<TResponse>("workspace/edituser", {
      workSpaceId,
      email,
      role
    }),
  updateUserName: <TResponse>(name: string) =>
    requests.patch<TResponse>("/user/changename", { name }),
  // updateUserShortName: <TResponse>(shortName: string) =>
  //   requests.patch<TResponse>("/user/changeshortname", { shortName }),
  getUserRights: <TResponse>(workSpaceId: string, userId?: string) =>
    requests.get<TResponse>(
      `/user/rights?workSpaceId=${workSpaceId}${
        userId ? `&userId=${userId}` : ""
      }`
    ),
  makeUserInactive: <TResponse>(workSpaceId: string, userId: string) =>
    requests.patch<TResponse>("/user/inactive", { workSpaceId, userId }),
  makeUserActive: <TResponse>(workSpaceId: string, userId: string) =>
    requests.patch<TResponse>("/user/active", { workSpaceId, userId }),
  removeUser: <TResponse>(workSpaceId: string, userId: string) =>
    requests.post<TResponse>("/workspace/removeuser", { workSpaceId, userId })
};

const Payment = {
  getCurrentSubscription: <TResponse>(workSpaceId: string) => {
    return requests.get<TResponse>(
      `/payment/currentsubscription?workSpaceId=${workSpaceId}`
    );
  },
  getPlansList: <TResponse>(
    workSpaceId: string,
    purchaseType: PurchaseType
  ) => {
    return requests.get<TResponse>(
      `/payment/list?purchaseType=${purchaseType}&workSpaceId=${workSpaceId}`
    );
  },
  getCalculatedPrice: <TResponse>(
    workSpaceId: string,
    planId: string,
    purchaseType: PurchaseType
  ) => {
    return requests.get<TResponse>(
      `/payment/calculateprice?workSpaceId=${workSpaceId}&planId=${planId}&purchaseType=${purchaseType}`
    );
  },
  initializePayment: <TResponse>(
    workSpaceId: string,
    planId: string,
    paymentType: PurchaseType,
    amount: number,
    GSTIN: string,
    legalName: string,
    address: string,
    expiryDate: Date
  ) => {
    return requests.post<TResponse>(`/payment`, {
      workSpaceId,
      planId,
      paymentType,
      amount,
      GSTIN,
      legalName,
      address,
      expiryDate
    });
  },
  convertToFreePlan: <TResponse>(workSpaceId: string) => {
    return requests.post<TResponse>(`/payment/convertfreeplan`, {
      workSpaceId
    });
  },
  getOrderList: <TResponse>(workSpaceId: string) => {
    return requests.get<TResponse>(
      `/order/orderlist?workSpaceId=${workSpaceId}`
    );
  },
  updateOrder: (data: {
    orderId: string;
    razorpayPaymentId: string;
    razorpaySignature: string;
    razorpayOrderId: string;
  }) => {
    return requests.patch(`/payment/updateorder`, data);
  },
  getPaymentStatus: <TResponse>(order: string) =>
    requests.get<TResponse>(`/order/?orderId=${order}`),
  getClientLimits: <TResponse>(workSpaceId: string) => {
    return requests.get<TResponse>(
      `/payment/clientlimits?workSpaceId=${workSpaceId}`
    );
  },
  getInvoices: <TResponse>(workSpaceId: string) => {
    return requests.get<TResponse>(
      `/payment/getinvoices?workSpaceId=${workSpaceId}`
    );
  },
  downloadInvoice: (workSpaceId: string, invoiceId: string) => {
    return requests.getBlob(
      `/payment/downloadinvoice?workSpaceId=${workSpaceId}&invoiceId=${invoiceId}`,
      { responseType: "blob" }
    );
  }
};

const Clients = {
  getClient: <TResponse>(workSpaceId: string, clientId: string) => {
    return requests.get<TResponse>(
      `/client/getClient?workSpaceId=${workSpaceId}&clientId=${clientId}`
    );
  },
  getClients: <TResponse>(
    workSpaceId: string,
    query: {
      limit: number;
      skip: number;
      searchText: string;
      active: boolean;
      type: ClientType;
      download: boolean;
    }
  ) => {
    return query.download
      ? requests.getBlob(
          `/client/list?workSpaceId=${workSpaceId}&query=${JSON.stringify(
            query
          )}`,
          { responseType: "blob" }
        )
      : requests.get<TResponse>(
          `/client/list?workSpaceId=${workSpaceId}&query=${JSON.stringify(
            query
          )}`
        );
  },
  addClient: <TResponse>(clientDetails: ClientDetailsToAdd) => {
    return requests.post<TResponse>(`/client/add`, clientDetails);
  },
  editClient: <TResponse>(clientDetails: ClientDetailsWithClientId) => {
    return requests.post<TResponse>(`/client/edit`, clientDetails);
  },
  makeClientActive: <TResponse>(workSpaceId: string, clientId: string) => {
    return requests.post<TResponse>(`/client/active`, {
      workSpaceId,
      clientId
    });
  },
  makeClientInactive: <TResponse>(workSpaceId: string, clientId: string) => {
    return requests.post<TResponse>(`/client/inactive`, {
      workSpaceId,
      clientId
    });
  },
  deleteClient: <TResponse>(workSpaceId: string, clientIds: string[]) => {
    return requests.post<TResponse>(`/client/delete`, {
      workSpaceId,
      clientIds
    });
  },
  getBulkUploadSheet: (workSpaceId: string) =>
    requests.getBlob(`/client/getimporttemplate?workSpaceId=${workSpaceId}`, {
      responseType: "blob"
    }),
  getAllBulkUploadRequests: (workSpaceId: string) =>
    requests.get(`/bulkupload/client/requests?workSpaceId=${workSpaceId}`),
  getOnebulkUploadRequest: (workSpaceId: string, requestId: string) =>
    requests.get(
      `/bulkupload/client/request?workSpaceId=${workSpaceId}&requestId=${requestId}`
    ),
  validateUpload: <TResponse>(workSpaceId: string, file: File) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("workSpaceId", workSpaceId);
    return requests.post<TResponse>(
      `/bulkupload/client/validateSheet`,
      formData
    );
  },
  uploadClients: <TResponse>(workSpaceId: string, file: File) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("workSpaceId", workSpaceId);
    return requests.post<TResponse>(`/client/uploadclients`, formData);
  }
};

const FrontendVersion = {
  getFrontendVersion: <TResponse>() => {
    return requests.get<TResponse>(`/version/frontend`);
  }
};

const agent = {
  setToken: (_token: string | undefined) => (token = _token),
  Auth,
  Firm,
  User,
  Payment,
  Clients,
  FrontendVersion
};

export default agent;
