import { Fragment, useState } from "react";
import {
  CheckIcon,
  MinusIcon,
  CheckCircleIcon
} from "@heroicons/react/20/solid";
import { AvailablePlan } from ".";
import getCommaSeparatedValue from "../../helpers/getCommaSeparatedValue";

const planSections = [
  {
    name: "Features",
    features: [
      { name: "GST Clients" },
      { name: "IT Client Limit" },
      { name: "DGFT Client Limit" },
      { name: "E-Invoice Client Limit" },
      { name: "E-Way Client Limit" },
      { name: "MCA Client Limit" },
      { name: "Traces Client Limit" }
      // {
      //   name: "Users",
      //   plans: {
      //     Free: "Unlimited",
      //     Basic: "Unlimited",
      //     Premium: "Unlimited",
      //     Enterprise: "Unlimited"
      //   }
      // },
      // {
      //   name: "All Features",
      //   plans: {
      //     Free: true,
      //     Basic: true,
      //     Premium: true,
      //     Enterprise: true
      //   }
      // }
    ]
  }
] as const;

interface PlanProps {
  availablePlans: AvailablePlan[];
  onPlanSelection: (planName: AvailablePlan) => void;
  convertToFreePlan: () => void;
}

export default function Plans({
  availablePlans,
  onPlanSelection,
  convertToFreePlan
}: PlanProps) {
  const [selectedPlan, setSelectedPlan] = useState<AvailablePlan>();

  const handleSelection = (plan: AvailablePlan) => {
    setSelectedPlan(plan);
    onPlanSelection(plan);
  };

  return (
    <div className="mx-auto max-w-7xl py-6">
      {/* xs to lg */}
      <div className="mx-auto max-w-md space-y-8 lg:hidden">
        {availablePlans.map(plan => (
          <section
            key={plan._id}
            className={`${
              selectedPlan?._id === plan._id
                ? "rounded-xl bg-gray-400/5 ring-1 ring-inset ring-indigo-600 m-4"
                : ""
            } p-8`}
          >
            <h3
              id={plan._id}
              className="text-sm font-semibold leading-6 text-gray-900 flex items-center gap-x-8 justify-between"
            >
              {plan.name}
              <CheckCircleIcon
                className={`${
                  selectedPlan?._id === plan._id ? "" : "invisible"
                } h-5 w-5 text-indigo-600`}
                aria-hidden="true"
              />
            </h3>
            <p className="mt-2 flex items-baseline gap-x-1 text-gray-900">
              <span className="text-4xl font-bold">
                {getCommaSeparatedValue(plan.price)}
              </span>
              <span className="text-sm font-semibold">/Year</span>
            </p>
            <ul className="mt-4 space-y-4 text-sm leading-6 text-gray-900">
              {planSections.map(section => (
                <li key={section.name}>
                  <ul className="space-y-4">
                    {section.features.map(feature =>
                      "plans" in feature ? (
                        // <li key={feature.name} className="flex gap-x-3">
                        //   <CheckIcon
                        //     className="h-6 w-5 flex-none text-indigo-600"
                        //     aria-hidden="true"
                        //   />
                        //   <span>
                        //     {feature.name}{" "}
                        //     {typeof feature.plans[plan.name] === "string" ||
                        //     typeof feature.plans[plan.name] === "number" ? (
                        //       <span className="text-sm leading-6 text-gray-500">
                        //         ({feature.plans[plan.name]})
                        //       </span>
                        //     ) : null}
                        //   </span>
                        // </li>
                        <></>
                      ) : (
                        <li key={feature.name} className="flex gap-x-3">
                          <CheckIcon
                            className="h-6 w-5 flex-none text-gray-400"
                            aria-hidden="true"
                          />
                          <span>
                            {feature.name}{" "}
                            {feature.name === "GST Clients" ? (
                              <span className="text-sm leading-6 text-gray-500">
                                ({getCommaSeparatedValue(plan.gstClientLimit)})
                              </span>
                            ) : feature.name === "IT Client Limit" ? (
                              <span className="text-sm leading-6 text-gray-500">
                                ({getCommaSeparatedValue(plan.itClientLimit)})
                              </span>
                            ) : feature.name === "DGFT Client Limit" ? (
                              <span className="text-sm leading-6 text-gray-500">
                                ({getCommaSeparatedValue(plan.dgftClientLimit)})
                              </span>
                            ) : feature.name === "E-Invoice Client Limit" ? (
                              <span className="text-sm leading-6 text-gray-500">
                                (
                                {getCommaSeparatedValue(
                                  plan.eInvoiceClientLimit
                                )}
                                )
                              </span>
                            ) : feature.name === "E-Way Client Limit" ? (
                              <span className="text-sm leading-6 text-gray-500">
                                ({getCommaSeparatedValue(plan.ewayClientLimit)})
                              </span>
                            ) : feature.name === "MCA Client Limit" ? (
                              <span className="text-sm leading-6 text-gray-500">
                                ({getCommaSeparatedValue(plan.mcaClientLimit)})
                              </span>
                            ) : feature.name === "Traces Client Limit" ? (
                              <span className="text-sm leading-6 text-gray-500">
                                (
                                {getCommaSeparatedValue(plan.tracesClientLimit)}
                                )
                              </span>
                            ) : null}
                          </span>
                        </li>
                      )
                    )}
                  </ul>
                </li>
              ))}
            </ul>
            <button
              aria-describedby={plan._id}
              onClick={() =>
                plan.name === "Free"
                  ? convertToFreePlan()
                  : handleSelection(plan)
              }
              className={`mt-8 block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 ${
                selectedPlan?._id === plan._id
                  ? "bg-indigo-600 text-white"
                  : "text-indigo-600 ring-1 ring-inset ring-indigo-200 hover:ring-indigo-300"
              }`}
            >
              {plan.name === "Free" ? "Convert to Free Plan" : "Purchase"}
            </button>
          </section>
        ))}
      </div>

      {/* lg+ */}
      <div
        id="table-scroll"
        className="py-6 isolate hidden lg:block overflow-auto"
      >
        <div className="relative">
          {selectedPlan?._id ? (
            <div className="absolute inset-x-4 inset-y-0 -z-[1] flex">
              <div
                className="flex px-4"
                aria-hidden="true"
                style={{
                  width: `${100 / (availablePlans.length + 1)}%`,
                  marginLeft: `${
                    (availablePlans.findIndex(
                      plan => plan?._id === selectedPlan?._id
                    ) +
                      1) *
                    (100 / (availablePlans.length + 1))
                  }%`
                }}
              >
                <div className="w-full -mb-6 rounded-xl border border-indigo-600 bg-gray-400/5" />
              </div>
            </div>
          ) : null}
          <table className="w-full table-fixed border-separate border-spacing-x-8 text-left">
            <caption className="sr-only">Pricing plan comparison</caption>
            <colgroup>
              {[...Array(availablePlans.length + 1)].map((_, index) => (
                <col
                  key={index}
                  style={{
                    width: `${100 / availablePlans.length + 1}%`
                  }}
                />
              ))}
            </colgroup>
            <thead>
              <tr>
                <th scope="row">
                  <span className="sr-only">Price</span>
                </th>
                {availablePlans.map(plan => (
                  <td key={plan._id} className="px-6 pt-4 xl:px-8 relative">
                    <div className="flex flex-col items-center gap-2 text-gray-900">
                      <div className="flex gap-8 items-center justify-between text-sm font-semibold leading-7 text-gray-900">
                        {plan.name}
                        <CheckCircleIcon
                          className={`${
                            selectedPlan?._id === plan._id
                              ? "top-4 right-4"
                              : "invisible"
                          } absolute h-5 w-5 text-indigo-600`}
                          aria-hidden="true"
                        />
                      </div>
                      <span>
                        <span className="text-4xl font-bold">
                          {getCommaSeparatedValue(plan.price)}
                        </span>
                        <span className="text-sm font-semibold leading-6">
                          /Year
                        </span>
                      </span>
                    </div>
                  </td>
                ))}
              </tr>
            </thead>
            <tbody>
              {planSections.map(section => (
                <Fragment key={section.name}>
                  <tr>
                    <th className="py-4"></th>
                  </tr>
                  <tr>
                    <th
                      scope="colgroup"
                      colSpan={availablePlans.length + 1}
                      className="bg-gray-50 py-4 px-6 text-sm font-medium text-gray-900 text-left relative -z-[2]"
                    >
                      {section.name}
                      <div className="absolute inset-x-8 mt-4 h-px bg-gray-900/10" />
                    </th>
                  </tr>
                  {section.features.map(feature => (
                    <tr key={feature.name}>
                      <th
                        scope="row"
                        className="py-4 px-6 text-sm font-normal leading-6 text-gray-900"
                      >
                        {feature.name}
                        <div className="absolute inset-x-8 mt-4 h-px bg-gray-900/5" />
                      </th>
                      {availablePlans.map(plan =>
                        "plans" in feature ? (
                          // <td key={plan._id} className="px-6 py-4 xl:px-8">
                          //   {typeof feature.plans[plan.name] === "string" ||
                          //   typeof feature.plans[plan.name] === "number" ? (
                          //     <div className="text-center text-sm leading-6 text-gray-500">
                          //       {feature.plans[plan.name]}
                          //     </div>
                          //   ) : (
                          //     <>
                          //       {feature.plans[plan.name] === true ? (
                          //         <CheckIcon
                          //           className="mx-auto h-5 w-5 text-indigo-600"
                          //           aria-hidden="true"
                          //         />
                          //       ) : (
                          //         <MinusIcon
                          //           className="mx-auto h-5 w-5 text-gray-400"
                          //           aria-hidden="true"
                          //         />
                          //       )}

                          //       <span className="sr-only">
                          //         {feature.plans[plan.name] === true
                          //           ? "Included"
                          //           : "Not included"}{" "}
                          //         in {plan.name}
                          //       </span>
                          //     </>
                          //   )}
                          // </td>
                          <></>
                        ) : (
                          <td key={plan._id} className="px-6 py-4 xl:px-8">
                            {feature.name === "GST Clients" ? (
                              <div className="text-center text-sm leading-6 text-gray-500">
                                {getCommaSeparatedValue(plan.gstClientLimit)}
                              </div>
                            ) : feature.name === "IT Client Limit" ? (
                              <div className="text-center text-sm leading-6 text-gray-500">
                                {getCommaSeparatedValue(plan.itClientLimit)}
                              </div>
                            ) : feature.name === "DGFT Client Limit" ? (
                              <div className="text-center text-sm leading-6 text-gray-500">
                                {getCommaSeparatedValue(plan.dgftClientLimit)}
                              </div>
                            ) : feature.name === "E-Invoice Client Limit" ? (
                              <div className="text-center text-sm leading-6 text-gray-500">
                                {getCommaSeparatedValue(
                                  plan.eInvoiceClientLimit
                                )}
                              </div>
                            ) : feature.name === "E-Way Client Limit" ? (
                              <div className="text-center text-sm leading-6 text-gray-500">
                                {getCommaSeparatedValue(plan.ewayClientLimit)}
                              </div>
                            ) : feature.name === "MCA Client Limit" ? (
                              <div className="text-center text-sm leading-6 text-gray-500">
                                {getCommaSeparatedValue(plan.mcaClientLimit)}
                              </div>
                            ) : feature.name === "Traces Client Limit" ? (
                              <div className="text-center text-sm leading-6 text-gray-500">
                                {getCommaSeparatedValue(plan.tracesClientLimit)}
                              </div>
                            ) : (
                              <>
                                <CheckIcon
                                  className="mx-auto h-5 w-5 text-indigo-600"
                                  aria-hidden="true"
                                />
                                <span className="sr-only">
                                  Included in {plan.name}
                                </span>
                              </>
                            )}
                          </td>
                        )
                      )}
                    </tr>
                  ))}
                </Fragment>
              ))}
              <tr>
                <td></td>
                {availablePlans.map(plan => (
                  <td key={plan._id} className="px-6 pt-2">
                    <button
                      onClick={() =>
                        plan.name === "Free"
                          ? convertToFreePlan()
                          : handleSelection(plan)
                      }
                      className={`mt-8 mx-auto block rounded-md py-2 px-4 w-full text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 ${
                        selectedPlan?._id === plan._id
                          ? "bg-indigo-600 text-white"
                          : "text-indigo-600 ring-1 ring-inset ring-indigo-200 hover:ring-indigo-300"
                      }`}
                    >
                      {plan.name === "Free"
                        ? "Convert to Free Plan"
                        : "Purchase"}
                    </button>
                  </td>
                ))}
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
