import Dashboard from "../../components/Dashboard";
import { ADD_NOTIFICATION } from "../../store/types";
import TagManager from "react-gtm-module";
import { connect, ConnectedProps } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import { withRouter, WithRouterProps } from "../../helpers/withRouter";
import { Component, ComponentType } from "react";
import agent from "../../agent";
import { formatDate } from "../../helpers/formatDate";
import { compose } from "redux";
import Plans from "./Plans";
import RadioButtonGroup from "../../components/RadioButtonGroup";
import Icon from "../../components/Icon";
import getCommaSeparatedValue from "../../helpers/getCommaSeparatedValue";
import Skeleton from "react-loading-skeleton";
import Button from "../../components/Button";
import { DocumentTextIcon } from "@heroicons/react/24/outline";
import { NotificationType } from "../../store/reducers/notification";
import validateGSTIN from "../../helpers/GSTValidationFunction";
import { loadScript } from "../../helpers/loadScript";
import classNames from "classnames";

const tagManagerArgs = {
  dataLayer: {
    userId: "001",
    userProject: "TaxPido Login Tool",
    page: "Subscription"
  },
  dataLayerName: "PageDataLayer"
};

const mapStateToProps = (state: RootState) => ({
  ...state.notification,
  ...state.common
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  addNotification: (title: string, message: string, type: NotificationType) =>
    dispatch({ type: ADD_NOTIFICATION, payload: { title, message, type } })
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = Partial<PropsFromRedux & WithRouterProps> & {};

type AvailablePaymentType = {
  title: "Purchase" | "Renewal" | "Upgrade";
  description: string;
};

const initialPurchaseType: AvailablePaymentType = {
  title: "Purchase",
  description: "Purchase a new plan for your firm."
};

export type PurchaseType = AvailablePaymentType["title"];

interface CurrentSubscription {
  planName: string;
  currentPlanId: string;
  expiryDate: string;
  planStartDate: string;
  paymentTypesAvailable: AvailablePaymentType[];
}

const clientLimitHeader = [
  { label: "Client Types", field: "clientType" },
  { label: "Limit as per your Plan", field: "limit" },
  { label: "Currently Used (Only Active Clients)", field: "Active" },
  { label: "Available Limit", field: "available" },
  { label: "Inactive Clients", field: "Inactive" }
] as const;

type ClientLimitField = (typeof clientLimitHeader)[number]["field"];

const clientLimitLabelMap = {
  gstClients: "GST Clients",
  ewayClients: "E-Way Bill Clients",
  eInvoiceClients: "E-Invoice Clients",
  itClients: "IT Clients",
  tracesClients: "Traces Clients",
  mcaClients: "MCA Clients",
  dgftClients: "DGFT Clients"
} as const;

type ClientLimitLabel = keyof typeof clientLimitLabelMap;
type ClientLimitData = { limit: number; Active: number; Inactive: number };

type ClientLimits = Record<ClientLimitLabel, ClientLimitData>;

const clientLimitInitialData: ClientLimits = {
  gstClients: { limit: 0, Active: 0, Inactive: 0 },
  ewayClients: { limit: 0, Active: 0, Inactive: 0 },
  eInvoiceClients: { limit: 0, Active: 0, Inactive: 0 },
  itClients: { limit: 0, Active: 0, Inactive: 0 },
  tracesClients: { limit: 0, Active: 0, Inactive: 0 },
  mcaClients: { limit: 0, Active: 0, Inactive: 0 },
  dgftClients: { limit: 0, Active: 0, Inactive: 0 }
};

export type PlanName = "Free" | "Basic" | "Premium" | "Enterprise";

export interface AvailablePlan {
  _id: string;
  name: PlanName;
  type: "paid";
  price: number;
  active: boolean;
  expiry: number;
  gstClientLimit: number;
  dgftClientLimit: number;
  eInvoiceClientLimit: number;
  ewayClientLimit: number;
  itClientLimit: number;
  mcaClientLimit: number;
  tracesClientLimit: number;
}

export interface PriceDetails {
  planPrice: number;
  totalAmount: number;
  totalGST: number;
  totalAmountWithGST: number;
  grossAmountExcludingGST: number;
  newExpiryDate: Date;
  // creditsLeft: number | null;
  // currentExpiryDate: Date;
  // daysDifference: number;
}

export interface OrderDetails {
  _id: string;
  status: "PENDING" | "TXN_SUCCESS" | "TXN_FAILURE";
  userId: string;
  workSpaceId: string;
  gstin: string;
  gstinName: string;
  amount: number;
  expiryDate: Date;
  planId: number;
  orderId: string;
  paymentType: NonNullable<PurchaseType>;
  respmsg?: string;
}

interface State {
  loading: boolean;
  currentSubscription: CurrentSubscription;
  clientLimits: ClientLimits;
  purchaseType: AvailablePaymentType;
  availablePlans: AvailablePlan[];
  selectedPlan: AvailablePlan | null;
  priceDetails: PriceDetails | undefined;
  gstin: string;
  legalName: string;
  address: string;
}

class Subscription extends Component<Props, State> {
  state: State = {
    loading: false,
    currentSubscription: {
      planName: "",
      currentPlanId: "",
      expiryDate: "",
      planStartDate: "",
      paymentTypesAvailable: []
    },
    clientLimits: clientLimitInitialData,
    purchaseType: initialPurchaseType,
    availablePlans: [],
    selectedPlan: null,
    priceDetails: undefined,
    gstin: "",
    legalName: "",
    address: ""
  };
  paymentDetailsRef: HTMLDivElement | null = null;
  plansRef: HTMLDivElement | null = null;

  componentDidMount() {
    document.title = "Subscriptions - TaxPido Login Tool";

    this.getCurrentSubscription();
    this.getClientLimits();
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (prevProps.params?.firmId !== this.props.params?.firmId) {
      this.getCurrentSubscription();
      this.getClientLimits();
    }
    if (this.state.availablePlans !== prevState.availablePlans) {
      this.plansRef?.scrollIntoView({ behavior: "smooth" });
    }
    if (
      prevState.priceDetails?.totalAmount !==
      this.state.priceDetails?.totalAmount
    ) {
      this.paymentDetailsRef?.scrollIntoView({ behavior: "smooth" });
    }
    if ((prevProps as any)?.currentFirm?._id !== this.props?.currentFirm?._id) {
      this.setState({
        purchaseType: initialPurchaseType,
        availablePlans: [],
        selectedPlan: null
      });
    }
    if (prevState.purchaseType !== this.state.purchaseType) {
      this.setState({ availablePlans: [], selectedPlan: null });
    }
    if (
      (prevProps as any)?.currentFirm?._id !== this.props?.currentFirm?._id ||
      prevState.purchaseType !== this.state.purchaseType
    ) {
      this.setState({
        priceDetails: undefined,
        gstin: "",
        legalName: "",
        address: ""
      });
    }
  }

  getCurrentSubscription = () => {
    const workSpaceId = this.props.params?.firmId;

    this.setState({ loading: true });
    workSpaceId &&
      agent.Payment.getCurrentSubscription<CurrentSubscription>(workSpaceId)
        .then(res => {
          this.setState(
            { currentSubscription: res, loading: false },
            () =>
              res.paymentTypesAvailable.length > 0 &&
              this.handlePurchaseTypeSelection(res.paymentTypesAvailable[0])
          );
        })
        .catch(err => {
          this.setState({ loading: false });
          this.props.addNotification?.(
            "Could not fetch current subscription details",
            "Error while fetching current subscription",
            "danger"
          );
        });
  };

  getClientLimits = () => {
    const workSpaceId = this.props.params?.firmId;

    this.setState({ loading: true });
    workSpaceId &&
      agent.Payment.getClientLimits<ClientLimits>(workSpaceId)
        .then(res => {
          this.setState({ clientLimits: res, loading: false });
        })
        .catch(err => {
          this.setState({ loading: false });
          this.props.addNotification?.(
            "Could not fetch client limits",
            "Error while fetching client limits",
            "danger"
          );
        });
  };

  getClientLimitRowData = (
    label: ClientLimitLabel,
    rowData: ClientLimitData,
    field: ClientLimitField
  ) => {
    return field === "clientType"
      ? clientLimitLabelMap[label]
      : field === "available"
      ? rowData.limit - rowData.Active
      : rowData[field];
  };

  getPlansList = () => {
    this.setState({ loading: true });
    const workSpaceId = this.props.params?.firmId;

    workSpaceId &&
      agent.Payment.getPlansList<{ availablePlans: AvailablePlan[] }>(
        workSpaceId,
        this.state.purchaseType.title
      )
        .then(res => {
          this.setState({ availablePlans: res.availablePlans, loading: false });
        })
        .catch(err => {
          this.setState({ loading: false });
          this.props.addNotification?.(
            "Could not fetch plans list",
            "Error while fetching plans list",
            "danger"
          );
        });
  };

  handlePurchaseTypeSelection = (item: AvailablePaymentType) => {
    this.setState({ purchaseType: item }, this.getPlansList);
  };

  calculatePrice = (plan: AvailablePlan) => {
    const planPrice = plan.price;
    const totalAmount = plan.price;
    // const gstRate = 18;
    const totalGST = 0; /* (totalAmount * gstRate) / 100 */
    const totalAmountWithGST = totalAmount + totalGST;
    const grossAmountExcludingGST = totalAmount - totalGST;

    const newExpiryDate = new Date(this.state.currentSubscription.expiryDate);
    newExpiryDate.setFullYear(newExpiryDate.getFullYear() + 1);

    this.setState({
      selectedPlan: plan,
      priceDetails: {
        planPrice,
        totalAmount,
        totalGST,
        totalAmountWithGST,
        grossAmountExcludingGST,
        newExpiryDate
      }
    });
  };

  convertToFreePlan = () => {
    const workSpaceId = this.props.params?.firmId;
    this.setState({ loading: true });

    workSpaceId &&
      agent.Payment.convertToFreePlan<{ message: string }>(workSpaceId)
        .then(res => {
          this.setState({ loading: false });
          this.props.addNotification?.(
            "Plan Downgraded",
            res.message === "success"
              ? "Plan downgraded successfully"
              : res.message,
            "success"
          );
          this.getCurrentSubscription();
        })
        .catch(err => {
          this.setState({ loading: false });
          this.props.addNotification?.(
            "Could not downgrade plan",
            err.response?.data?.message || err.message || err,
            "danger"
          );
        });
  };

  validateAndGetGSTINDetails = () => {
    // const workSpaceId = this.props.params?.firmId;
    // if (this.state.gstin.length === 15) {
    //   let isValid = validGSTIN(this.state.gstin);
    //   if (isValid) {
    //     this.setState({ loading: true });
    //     workSpaceId &&
    //       agent.Clients.getGSTdetails(workSpaceId, this.state.gstin)
    //         .then(response => {
    //           this.setState({
    //             loading: false,
    //             legalName: response.data.name || response.data.lgnm,
    //             address: response.data.address
    //           });
    //         })
    //         .catch((err: any) => {
    //           this.setState({ loading: false });
    //           this.props.addNotification?.(
    //             "Could not get GSTIN Data",
    //             err?.response?.data?.message || err?.message || err,
    //             "danger"
    //           );
    //         });
    //   } else {
    //     this.props.addNotification?.(
    //       "Incorrect GSTIN",
    //       "Please enter a valid GSTIN to proceed.",
    //       "danger"
    //     );
    //   }
    // } else {
    //   this.setState({ legalName: "", address: "" });
    // }
  };

  handleGstin = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ gstin: e.target.value }, this.validateAndGetGSTINDetails);
  };

  isDate<T>(val: T) {
    return Object.prototype.toString.call(val) === "[object Date]";
  }

  isObj<T>(val: T) {
    return typeof val === "object";
  }

  stringifyValue<T>(val: T) {
    if (this.isObj(val) && !this.isDate(val)) {
      return JSON.stringify(val);
    } else {
      return val;
    }
  }

  // buildForm({ action, params }: { action: string; params: any }) {
  //   const form = document.createElement("form");
  //   form.setAttribute("method", "post");
  //   form.setAttribute("action", action);

  //   Object.keys(params).forEach(key => {
  //     const input = document.createElement("input");
  //     input.setAttribute("type", "hidden");
  //     input.setAttribute("name", key);
  //     input.setAttribute("value", this.stringifyValue(params[key]));
  //     form.appendChild(input);
  //   });

  //   return form;
  // }

  // post<T>(details: { action: string; params: T }) {
  //   const form = this.buildForm(details);
  //   document.body.appendChild(form);
  //   form.submit();
  //   form.remove();
  // }

  // handlePayment = () => {
  //   const workSpaceId = this.props.params?.firmId;
  //   const {
  //     selectedPlan,
  //     purchaseType,
  //     priceDetails,
  //     gstin,
  //     legalName,
  //     address
  //   } = this.state;

  //   this.setState({ loading: true });

  //   workSpaceId &&
  //     purchaseType &&
  //     selectedPlan?._id &&
  //     agent.Payment.initializePayment<{ mid: string; orderId: string }>(
  //       workSpaceId,
  //       selectedPlan?._id,
  //       purchaseType.title,
  //       priceDetails.totalAmountWithGST,
  //       gstin,
  //       legalName,
  //       address,
  //       priceDetails.newExpiryDate
  //     )
  //       .then(res => {
  //         this.setState({ loading: false });
  //         const information = {
  //           action: `${process.env.REACT_APP_PAYTM_URL}/theia/api/v1/showPaymentPage?mid=${res.mid}&orderId=${res.orderId}`,
  //           params: res
  //         };
  //         this.post(information);
  //       })
  //       .catch(err => {
  //         this.setState({ loading: false });
  //         this.props.addNotification?.(
  //           "Could not fetch price details",
  //           "Error while fetching price details",
  //           "danger"
  //         );
  //       });
  // };

  handlePayment = async () => {
    const workSpaceId = this.props.params?.firmId;
    const {
      selectedPlan,
      purchaseType,
      priceDetails,
      gstin,
      legalName,
      address
    } = this.state;

    if (this.state.gstin) {
      const isValidGSTIN = validateGSTIN(this.state.gstin);

      if (!isValidGSTIN) {
        return this.props.addNotification?.(
          "Invalid GSTIN",
          "Please enter a valid GSTIN to proceed",
          "danger"
        );
      }
    }

    if (!workSpaceId || !selectedPlan || !purchaseType || !priceDetails) {
      return this.props.addNotification?.(
        "Error",
        "Invalid data to proceed with payment",
        "danger"
      );
    }

    this.setState(prev => ({ ...prev, loading: true }));

    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      return this.props.addNotification?.(
        "Error",
        "Razorpay SDK failed to load!",
        "danger"
      );
    }

    agent.Payment.initializePayment(
      workSpaceId,
      selectedPlan?._id as string,
      purchaseType.title,
      priceDetails.totalAmountWithGST,
      gstin || "",
      legalName || "",
      address || "",
      priceDetails.newExpiryDate
    )
      .then((result: any) => {
        this.setState(prev => ({ ...prev, loading: false }));
        const { amount, orderId } = result;

        const options = {
          key: process.env.REACT_APP_RAZORPAY_KEY_ID,
          amount: amount,
          currency: "INR",
          name: "TaxPido",
          description: `Payment for ${purchaseType.title} of ${selectedPlan.name} Plan`,
          order_id: orderId,
          notes: { appName: "LOGIN" },
          handler: async (response: any) => {
            const data = {
              orderId,
              razorpayPaymentId: response.razorpay_payment_id,
              razorpayOrderId: response.razorpay_order_id,
              razorpaySignature: response.razorpay_signature
            };

            agent.Payment.updateOrder(data)
              .then((res: any) => {
                if (res.success) {
                  this.props.addNotification?.(
                    "Payment Captured",
                    "Payment has been successfully captured",
                    "success"
                  );

                  this.props.navigate?.(`/paymentresponse?orderid=${orderId}`);
                }
              })
              .catch((err: any) => {
                console.log(err);
              });
          },
          modal: { confirm_close: true }
        };

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const paymentObject = new window.Razorpay(options);
        paymentObject.open();
      })
      .catch(err => {
        console.log(err);

        this.setState(prev => ({ ...prev, loading: false }));

        this.props.addNotification?.(
          "Error while fetching price details",
          "Could not fetch price details",
          "danger"
        );
      });
  };

  render() {
    TagManager.dataLayer(tagManagerArgs);

    return (
      <Dashboard>
        <div className="max-w-8xl mx-auto px-4 sm:px-6 md:px-8 space-y-8">
          <div className="flex items-center justify-between gap-6">
            <h1 className="text-2xl font-semibold text-gray-900 ">
              Subscription Details
            </h1>
            {/* <Button
              name="Invoices"
              type="link"
              to={`/${this.props.params?.firmId}/invoices`}
              icon={DocumentTextIcon}
            /> */}
          </div>
          <div className="bg-white shadow overflow-hidden sm:rounded-lg">
            <div className="px-8 py-5 sm:px-6">
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                Current Subscription
              </h3>
              <p className="mt-1 max-w-2xl text-sm text-gray-500">
                Shows your current subscriptions
              </p>
            </div>
            <div className="border-t border-gray-200 px-8 py-5 sm:p-0">
              <dl className="sm:divide-y sm:divide-gray-200 pb-4">
                <div className="py-4 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">
                    Current FIrm
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {this.props?.currentFirm?.name}
                  </dd>
                </div>
                <div className="py-4 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">
                    Current Plan
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {this.state.currentSubscription.planName}
                  </dd>
                </div>
                <div className="py-4 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">
                    Plan Start Date
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {formatDate(
                      this.state.currentSubscription.planStartDate,
                      false
                    )}
                  </dd>
                </div>
                <div className="py-4 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">
                    Plan Expiry Date
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {formatDate(
                      this.state.currentSubscription.expiryDate,
                      false
                    )}
                  </dd>
                </div>
              </dl>
            </div>
          </div>
          <div className="bg-white shadow overflow-hidden sm:rounded-lg py-4 px-6">
            <div className="pb-4">
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                Client limits
              </h3>
            </div>
            <div className="bg-white rounded-b-lg overflow-hidden">
              <div className="sm:flex-auto border-2 border-gray-200 rounded-md">
                <div id="table-scroll" className="overflow-auto">
                  <table className="w-full">
                    <thead className="bg-gray-50">
                      <tr>
                        {clientLimitHeader.map((header, index) => (
                          <th
                            key={header.label}
                            className={classNames(
                              "px-6 py-3 text-xs font-bold text-gray-500 uppercase tracking-wider whitespace-nowrap border-b border-gray-200",
                              index === 0 ? "text-left" : "text-center"
                            )}
                          >
                            {header.label}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {Object.entries(this.state.clientLimits).map(
                        ([label, rowData]) => (
                          <tr key={label} className="even:bg-gray-50">
                            {clientLimitHeader.map(({ field }, index) => {
                              return (
                                <td
                                  key={`${field}-${label}`}
                                  className={classNames(
                                    "px-6 py-4 whitespace-nowrap text-sm text-gray-900",
                                    index !== 0 && "text-center "
                                  )}
                                >
                                  {this.state.loading ? (
                                    <Skeleton height={20} />
                                  ) : (
                                    this.getClientLimitRowData(
                                      label as ClientLimitLabel,
                                      rowData,
                                      field
                                    )
                                  )}
                                </td>
                              );
                            })}
                          </tr>
                        )
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          {this.state.currentSubscription.paymentTypesAvailable.length > 0 && (
            <div className="bg-white shadow overflow-hidden sm:rounded-lg py-4 px-6">
              <div className="pb-2">
                <RadioButtonGroup
                  label="Select Purchase Type"
                  options={this.state.currentSubscription.paymentTypesAvailable}
                  selectedOption={this.state.purchaseType}
                  setSelectedOption={this.handlePurchaseTypeSelection}
                />
              </div>
            </div>
          )}
          {this.state.purchaseType && this.state.availablePlans.length > 0 && (
            <div
              ref={node => (this.plansRef = node)}
              className="bg-white shadow overflow-hidden sm:rounded-lg"
            >
              <Plans
                availablePlans={this.state.availablePlans}
                onPlanSelection={this.calculatePrice}
                convertToFreePlan={this.convertToFreePlan}
              />
              <p className="my-4 mx-12 text-sm">
                <span className="font-bold">Note</span> -{" "}
                <span className="italic">
                  All of the plans have the same features and unlimited users.
                </span>
              </p>
            </div>
          )}
          {this.state.priceDetails &&
            this.state.priceDetails.totalAmount > 0 && (
              <>
                <div
                  ref={node => (this.paymentDetailsRef = node)}
                  className="bg-white shadow overflow-hidden sm:rounded-lg py-4 px-6"
                >
                  <div className="pb-4">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">
                      Purchase Details
                    </h3>
                  </div>
                  <div className="border-t border-gray-200 px-8 py-5 sm:p-0">
                    <dl className="sm:divide-y sm:divide-gray-200 pb-4">
                      <div className="py-4 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-gray-500">
                          Payment For
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                          {this.state.purchaseType.title}
                        </dd>
                      </div>
                      <div className="py-4 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-gray-500">
                          Selected Plan
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                          {this.state.selectedPlan?.name}
                        </dd>
                      </div>
                      {/* <div className="py-4 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-sm font-medium text-gray-500">
                        Current Expiry Date
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        {this.state.priceDetails.currentExpiryDate &&
                          formatDate(
                            this.state.priceDetails.currentExpiryDate,
                            false
                          )}
                      </dd>
                      </div> */}
                      <div className="py-4 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-gray-500">
                          New Expiry Date
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                          {this.state.priceDetails.newExpiryDate &&
                            formatDate(
                              this.state.priceDetails.newExpiryDate,
                              false
                            )}
                        </dd>
                      </div>
                    </dl>
                  </div>
                </div>
                <div className="bg-white shadow overflow-hidden sm:rounded-lg py-4 px-6">
                  <div className="pb-4">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">
                      Payment Calculation
                    </h3>
                  </div>
                  <div className="border-t border-gray-200 px-8 py-5 sm:p-0">
                    <dl className="sm:divide-y sm:divide-gray-200 pb-4">
                      {this.state.purchaseType.title === "Upgrade" ? (
                        <>
                          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-semibold text-gray-700 sm:col-span-2">
                              Calculation of Plan Charges
                            </dt>
                          </div>
                          <div className="py-4 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">
                              Gross Plan Charges (A)
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                              ₹{" "}
                              {getCommaSeparatedValue(
                                this.state.priceDetails.planPrice,
                                true
                              )}
                            </dd>
                          </div>
                          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-semibold text-gray-700 sm:col-span-2">
                              Calculation of Proportionate Amount to be Deducted
                            </dt>
                          </div>
                          {/* <div className="py-4 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                          <dt className="text-sm font-medium text-gray-500">
                            Current Expiry Date
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                            {this.state.priceDetails.currentExpiryDate &&
                              formatDate(
                                this.state.priceDetails.currentExpiryDate,
                                false
                              )}
                          </dd>
                        </div>
                        <div className="py-4 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                          <dt className="text-sm font-medium text-gray-500">
                            Days Left
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                            {this.state.priceDetails.daysDifference}
                          </dd>
                        </div>
                        <div className="py-4 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                          <dt className="text-sm font-medium text-gray-500">
                            Credits Left (B)
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                            {this.state.priceDetails.creditsLeft
                              ? `₹ ${getCommaSeparatedValue(
                                  this.state.priceDetails.creditsLeft,
                                  true
                                )}`
                              : ""}
                          </dd>
                          </div> */}
                          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-semibold text-gray-700 sm:col-span-2">
                              Calculation of Payable Amount
                            </dt>
                          </div>
                          <div className="py-4 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">
                              Net Plan Charges (A-B)
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                              ₹{" "}
                              {getCommaSeparatedValue(
                                this.state.priceDetails.totalAmount,
                                true
                              )}
                            </dd>
                          </div>
                        </>
                      ) : (
                        <div className="py-4 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                          <dt className="text-sm font-medium text-gray-500">
                            Plan Charges
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                            ₹{" "}
                            {getCommaSeparatedValue(
                              this.state.priceDetails.planPrice,
                              true
                            )}
                          </dd>
                        </div>
                      )}
                      {/* <div className="py-4 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 items-center sm:px-6">
                      <dt className="text-sm font-medium text-gray-500">
                        Add GST @ 18%
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        <div className="flex flex-col sm:flex-row sm:items-center gap-4 lg:gap-16">
                          <div className="whitespace-nowrap">
                            ₹{" "}
                            {getCommaSeparatedValue(
                              this.state.priceDetails.totalGST,
                              true
                            )}
                          </div>
                          <div className="flex gap-4 items-center md:gap-6 flex-wrap">
                            <div className="min-w-[15rem]">
                              <input
                                type="text"
                                placeholder="Enter Your GSTIN"
                                value={this.state.gstin}
                                onChange={this.handleGstin}
                                className="border border-gray-300 rounded-md px-3 py-2 mt-1 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm"
                              />
                            </div>
                            <div className="font-semibold">
                              {this.state.legalName}
                            </div>
                          </div>
                        </div>
                      </dd>
                    </div> */}
                      <div className="py-4 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-gray-500">
                          Net Payable Amount
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                          ₹{" "}
                          {getCommaSeparatedValue(
                            this.state.priceDetails.totalAmountWithGST,
                            true
                          )}
                        </dd>
                      </div>
                    </dl>
                  </div>
                  {this.state.purchaseType &&
                    this.state.priceDetails.totalAmountWithGST && (
                      <button
                        className={`inline-flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white ${
                          this.state.priceDetails.totalAmountWithGST >= 0
                            ? "bg-indigo-600 hover:bg-indigo-700"
                            : "bg-indigo-300"
                        } disabled:cursor-not-allowed disabled:opacity-50`}
                        onClick={this.handlePayment}
                        disabled={
                          this.state.priceDetails.totalAmountWithGST < 0 ||
                          this.state.loading
                        }
                      >
                        {this.state.loading ? (
                          <Icon name="loading" className="-ml-1 mr-2 h-5 w-5" />
                        ) : (
                          <Icon
                            name="outline/rupee"
                            className="-ml-1 mr-2 h-5 w-5"
                            aria-hidden="true"
                          />
                        )}
                        Proceed to Payment
                      </button>
                    )}
                </div>
              </>
            )}
        </div>
      </Dashboard>
    );
  }
}

export default compose<ComponentType>(connector, withRouter)(Subscription);
