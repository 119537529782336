import React, { Fragment, PropsWithChildren } from "react";
import { Dialog, Disclosure, Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon, XMarkIcon } from "@heroicons/react/20/solid";
import { ConnectedProps, connect } from "react-redux";
import { Link } from "react-router-dom";
import { compose } from "redux";
import { WithRouterProps, withRouter } from "../../helpers/withRouter";
import { ADD_NOTIFICATION, LOGOUT, UPDATE_COMMON } from "../../store/types";
import Icon from "../Icon";
import SearchNavigation from "../SearchNavigation";
import {
  ArrowDownTrayIcon,
  ArrowTopRightOnSquareIcon,
  BugAntIcon,
  DocumentTextIcon
} from "@heroicons/react/24/outline";
import { formatDate } from "../../helpers/formatDate";
import { NotificationType } from "../../store/reducers/notification";
import ErrorBoundary from "../ErrorBoundry";
import Message from "../Message";
import MessageBar from "../MessageBar";
import ScrollToBottom from "../ScrollToTopBottom";
import { CommonState } from "../../store/reducers/common";
import { AppDispatch, RootState } from "../../store";
import agent from "../../agent";
import { User, WorkSpace } from "../../helpers/types";
import { adminRights, otherRights } from "../../constants/defaultUserRights";
import MultiSelect from "../MultiSelect";
import { UserRole } from "../../pages/Users/AddEditViewPage";

const menuItems = (firmId: string | undefined) => {
  return [
    {
      name: "Clients",
      iconName: "outline/users",
      route: `/${firmId}/clients/list`,
      children: [
        {
          name: "Add Client",
          iconName: "outline/user-plus",
          route: `/${firmId}/clients/add`
        },
        {
          name: "All Clients",
          iconName: "outline/users",
          route: `/${firmId}/clients/list`
        },
        {
          name: "Import",
          iconName: "outline/user-plus",
          route: `/${firmId}/clients/import`
        }
      ]
    },
    {
      name: "Users",
      iconName: "outline/users",
      route: `/${firmId}/user/list`,
      children: [
        {
          name: "Add User",
          iconName: "outline/user-plus",
          route: `/${firmId}/user/add`
        },
        {
          name: "All Users",
          iconName: "outline/users",
          route: `/${firmId}/user/list`
        }
      ]
    }
  ] as const;
};

type MenuItem = ReturnType<typeof menuItems>[number];
type MenuItemName = MenuItem["name"];
type MenuItemChildren = MenuItem["children"][number];
type MenuItemChildrenName = MenuItemChildren["name"];

type LinkProps = { onlyIcon?: boolean };

const BugReportLink = ({ onlyIcon }: LinkProps) => {
  return (
    <>
      {!onlyIcon && <hr className="my-4" />}
      <a
        href="https://docs.google.com/forms/d/e/1FAIpQLSfnWljG-qMhhAnmNJ97Y_VzHLKlyI9i6bpArarUHmE8ZSpSCQ/viewform"
        target="_blank"
        rel="noopener noreferrer"
        className={`text-gray-300 hover:bg-gray-700 hover:text-white group flex items-center text-xs font-medium rounded-md ${
          !onlyIcon && "px-2 py-2 gap-x-3"
        }`}
      >
        <BugAntIcon className="text-gray-400 flex-shrink-0 h-5 w-5" />
        {!onlyIcon && (
          <>
            <span>Bug report / Contact Support</span>
            <ArrowTopRightOnSquareIcon className="text-gray-400 flex-shrink-0 h-4 w-4 ml-auto" />
          </>
        )}
      </a>
    </>
  );
};

const ExtensionDownloadLink = ({ onlyIcon }: LinkProps) => {
  return (
    <a
      href="https://chrome.google.com/webstore/detail/jkidgkjdapbdaghckbnpfmpccdlodahk"
      target="_blank"
      rel="noopener noreferrer"
      className={`text-gray-300 hover:bg-gray-700 hover:text-white group flex items-center text-xs font-medium rounded-md mb-10 ${
        !onlyIcon && "px-2 py-2 gap-x-3"
      }`}
    >
      <ArrowDownTrayIcon className="text-gray-400 flex-shrink-0 h-5 w-5" />
      {!onlyIcon && (
        <>
          <span>New Login Tool Extension</span>
          <ArrowTopRightOnSquareIcon className="text-gray-400 flex-shrink-0 h-4 w-4 ml-auto" />
        </>
      )}
    </a>
  );
};

const UpdateLogsLink = ({ onlyIcon }: LinkProps) => {
  return (
    <div className="absolute bottom-0 inset-x-0 flex justify-center">
      {/* <a
        href="https://www.google.com"
        target="_blank"
        rel="noopener noreferrer"
        className={`text-gray-300 hover:underline group flex items-center text-[0.6rem] font-medium rounded-md ${
          !onlyIcon ? "px-2 py-3 gap-x-3" : "py-2"
        }`}
      >
        <DocumentTextIcon className="text-gray-400 flex-shrink-0 h-4 w-4" />
        {!onlyIcon && "Update Logs"}
      </a> */}
    </div>
  );
};

export const getSelfDetails = (
  workSpaceId: string | undefined,
  updateCommon?: (payload: Partial<CommonState>) => void,
  addNotification?: (
    title: string,
    message: string,
    type: NotificationType
  ) => void
) => {
  if (!workSpaceId) return;

  agent.User.getSelfDetails<{ user: User }>(workSpaceId)
    .then(response => {
      updateCommon?.({ currentUser: response.user });
    })
    .catch(err => {
      addNotification?.(
        "Could not load Firm Details",
        err?.response?.data?.message || err?.message || err,
        "danger"
      );
    });
};

export const getAllUsersList = (
  workSpaceId: string | undefined,
  updateCommon?: (payload: Partial<CommonState>) => void,
  addNotification?: (
    title: string,
    message: string,
    type: NotificationType
  ) => void
) => {
  if (!workSpaceId) return;

  const active = true;
  const includeCurrent = true;
  agent.User.getUserList<{
    users: User[];
  }>(workSpaceId, active, "", includeCurrent)
    .then(response => {
      updateCommon?.({
        users: response.users.map(
          ({ _id, name, shortname, email, mobileNumber, role }) => ({
            _id,
            name,
            email,
            mobileNumber,
            role,
            shortname
          })
        )
      });
    })
    .catch((err: any) => {
      addNotification?.(
        "Could not load Users List",
        err?.response?.data?.message || err?.message || err,
        "danger"
      );
    });
};

export type Rights = {
  _id: string;
  userId: string;
  workspaceId: string;
  userInvitationId: string;
  email: string;
  role: UserRole;
  createdAt: Date;
  updatedAt: Date;
};

export type RightsResponse = { allRights: boolean; rights: Rights };

/**
 * Function to get user rights for a workspace
 * @param workSpaceId
 * @param userId user id of user whose rights are to be fetched. If not provided, current user's rights will be fetched
 * @type "self" to get current user's rights or user id to get rights of that user
 * @param setState function to set rights in state or redux
 * @param addNotification function to show notification
 */

export const getUserRights = (
  workSpaceId: string,
  userId?: "self" | (string & {}), // this is to allow string auto completion for self but also allow any string for user id
  updateCommon?: (payload: Partial<CommonState>) => void,
  addNotification?: (
    title: string,
    message: string,
    type: NotificationType
  ) => void
) => {
  if (workSpaceId) {
    agent.User.getUserRights<RightsResponse>(
      workSpaceId,
      userId === "self" ? undefined : userId
    )
      .then(response => {
        if (
          ("allRights" in response && response.allRights) ||
          response.rights.role === "admin"
        ) {
          updateCommon?.({ rights: adminRights });
        } else if (response.rights.role === "other") {
          updateCommon?.({ rights: otherRights });
        }
      })
      .catch(err => {
        addNotification?.(
          "Could not fetch user rights",
          err?.response?.data?.message || err?.message || err,
          "danger"
        );
      });
  }
};
export const handleFirmChange = (
  item: WorkSpace | undefined,
  onLoad: boolean,
  updateCommon?: (payload: Partial<CommonState>) => void,
  addNotification?: (
    title: string,
    message: string,
    type: NotificationType
  ) => void
) => {
  return new Promise(resolve => {
    updateCommon?.({ currentFirm: item });
    localStorage.setItem("currentFirm", JSON.stringify(item ?? "{}"));
    !onLoad &&
      addNotification?.(
        "Firm Changed",
        `Current Firm ${item?.name}`,
        "success"
      );
    resolve(true);
  });
};

export const checkIfPlanExpired = (currentFirm?: WorkSpace) => {
  if (!currentFirm) return false;

  const { planExpiryDate, trialExpiryDate } = currentFirm;
  const expiryDate =
    planExpiryDate > trialExpiryDate ? planExpiryDate : trialExpiryDate;

  return new Date(expiryDate).getTime() < Date.now();
};

//Redux mapping
const mapStateToProps = (state: RootState) => ({
  ...state.common,
  ...state.notification
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  updateCommon: (payload: Partial<CommonState>) =>
    dispatch({ type: UPDATE_COMMON, payload }),
  onLogout: () => dispatch({ type: LOGOUT }),
  addNotification: (title: string, message: string, type: NotificationType) =>
    dispatch({ type: ADD_NOTIFICATION, payload: { title, message, type } })
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type DashboardProps = PropsWithChildren<
  Partial<PropsFromRedux & WithRouterProps>
>;

interface State {
  menuShow: boolean;
  loading: boolean;
  menuState:
    | Partial<Record<MenuItemName | MenuItemChildrenName, boolean>>
    | undefined;
  subMenuState: Partial<Record<MenuItemChildrenName, boolean>> | undefined;
  profileMenuShow: boolean;
  notifications: any[];
  unreadNotifications: number;
  shrinkMenu: boolean;
  infoFooterHeight: number;
  showInfoFooter: boolean;
  planExpiringInFifteenDays: boolean;
  planExpiryMessage: string;
}

class Dashboard extends React.Component<DashboardProps, State> {
  constructor(props: DashboardProps) {
    super(props);

    this.state = {
      loading: false,
      menuShow: false,
      menuState: {
        Clients: true,
        Users: true
      },
      subMenuState: undefined,
      profileMenuShow: false,
      shrinkMenu: false,
      notifications: [],
      unreadNotifications: 0,
      infoFooterHeight: 0,
      showInfoFooter: true,
      planExpiringInFifteenDays: false,
      planExpiryMessage: ""
    };
  }
  infoFooterRef = React.createRef<HTMLDivElement>();

  showHideInfoFooter = () => {
    this.setState({ showInfoFooter: !this.state.showInfoFooter });
  };

  componentDidMount = () => {
    this.getFirmsList();
    // this.updateRoute();
    this.toShrinkPage();
    // this.menuListToggle();

    this.setState({
      infoFooterHeight: this.infoFooterRef.current
        ? this.infoFooterRef.current?.clientHeight
        : 0
    });

    if (this.props.currentFirm?._id) {
      getSelfDetails(
        this.props.currentFirm?._id,
        this.props.updateCommon,
        this.props.addNotification
      );
      this.updatePathName();
      getUserRights(
        this.props.currentFirm?._id,
        "self",
        this.props.updateCommon,
        this.props.addNotification
      );
      this.setPlanExpiryMessage();
    }
  };

  getFirmsList = () => {
    const { updateCommon, navigate, addNotification } = this.props;
    this.setState({ loading: true });
    agent.Firm.getFirms<{
      workspaces: WorkSpace[];
    }>()
      .then(response => {
        if (response.workspaces.length === 0) {
          updateCommon?.({ isFirmPresent: false });
          navigate?.("/firms");
        }
        updateCommon?.({ firms: response.workspaces, isFirmPresent: true });
      })
      .catch(err => {
        addNotification?.(
          "Could not load Firm List",
          err?.response?.data?.message || err?.message || err,
          "danger"
        );
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  };

  onFirmChange = (firm: WorkSpace, onload: boolean) =>
    handleFirmChange(
      firm,
      onload,
      this.props.updateCommon,
      this.props.addNotification
    );

  componentDidUpdate = (prevProps: DashboardProps, prevState: State) => {
    const prevFirmIdParam = prevProps.params?.firmId;
    const currFirmIdParam = this.props.params?.firmId;
    if (
      (prevFirmIdParam !== currFirmIdParam && currFirmIdParam?.length === 24) ||
      (prevProps.firms?.length !== this.props.firms?.length &&
        this.props.firms?.length)
    ) {
      this.setCurrentFirm();
    }

    if (
      prevProps.currentFirm !== this.props.currentFirm &&
      this.props.currentFirm?._id
    ) {
      getSelfDetails(
        this.props.currentFirm?._id,
        this.props.updateCommon,
        this.props.addNotification
      );
      this.updatePathName();
      getUserRights(
        this.props.currentFirm?._id,
        "self",
        this.props.updateCommon,
        this.props.addNotification
      );
      this.setPlanExpiryMessage();
    }

    const prevPathname = prevProps.location?.pathname;
    const currPathname = this.props.location?.pathname;
    if (prevPathname !== currPathname) {
      // this.menuListToggle();
      this.setState({ menuShow: false });
    }
    if (prevState.showInfoFooter !== this.state.showInfoFooter) {
      this.setState({
        infoFooterHeight: this.state.showInfoFooter
          ? this.infoFooterRef.current?.clientHeight || 0
          : 0
      });
    }
  };

  //Function to set the firm in user account on login as well as on firm change
  setCurrentFirm = () => {
    const { params, firms, currentFirm } = this.props;
    const firmId = params?.firmId;
    const localCurrentFirm = JSON.parse(
      localStorage.getItem("currentFirm") || "{}"
    );

    const selectFirm = firms?.find(firm => firm._id === firmId);
    const selectedLocalFirm = firms?.find(
      firm => firm._id === localCurrentFirm._id
    );

    if (selectFirm) {
      this.changeFirm(selectFirm);
    } else if (selectedLocalFirm) {
      this.changeFirm(selectedLocalFirm);
    } else if (firms && firms.length > 0) {
      this.changeFirm(firms[0]);
    } else if (currentFirm) {
      this.changeFirm(currentFirm);
    }
  };

  changeFirm = (firm: WorkSpace) => {
    this.props.updateCommon?.({ currentFirm: firm });
    localStorage.setItem("currentFirm", JSON.stringify(firm ?? "{}"));
  };

  setPlanExpiryMessage = () => {
    const { currentFirm, currentUser } = this.props;

    const isExpiringInDays = (expiryDate: Date, days: number): boolean => {
      const timeDiff = expiryDate.getTime() - new Date().getTime();
      return timeDiff < days * 24 * 60 * 60 * 1000 && timeDiff >= 0;
    };

    const expiryDate =
      currentFirm?.planExpiryDate ?? currentFirm?.trialExpiryDate;
    if (!expiryDate) return;

    const expiryDateObj = new Date(expiryDate);
    const planExpiringInFifteenDays = isExpiringInDays(expiryDateObj, 15);
    const planExpiringInThreeDays = isExpiringInDays(expiryDateObj, 3);

    let planExpiryMessage = "";
    if (checkIfPlanExpired(currentFirm)) {
      planExpiryMessage = `Your subscription has already expired on ${formatDate(
        expiryDateObj,
        false
      )}. Click here to renew.`;
    } else if (
      currentFirm?.isOwner ||
      currentUser?.role === "Super Admin" ||
      currentUser?.role === "admin"
    ) {
      if (planExpiringInFifteenDays || planExpiringInThreeDays) {
        planExpiryMessage = `Your subscription is going to expire on ${formatDate(
          expiryDateObj,
          false
        )}. Click here to renew.`;
      }
    }

    this.setState({ planExpiryMessage });
  };

  sendExpiryNotification = () => {
    this.props.addNotification?.(
      "Plan Expired",
      "Your plan has expired. Please renew or Upgrade your plan to continue using the application.",
      "danger"
    );
  };

  moveToSubscriptionPage = () => {
    const currentFirm = this.props.currentFirm;

    if (checkIfPlanExpired(currentFirm)) {
      this.sendExpiryNotification();
    }
    if (window.location.href.includes("/paymentresponse")) return;
    this.props.navigate?.(`/${currentFirm?._id}/subscriptions`, {
      replace: true
    });
  };

  updatePathName = () => {
    const { currentFirm, location, params, navigate } = this.props;

    if (checkIfPlanExpired(currentFirm)) {
      return this.moveToSubscriptionPage();
    }

    const firmId = currentFirm?._id || "";

    if (
      location?.pathname === "/paymentresponse" ||
      location?.pathname === "/firms" ||
      location?.pathname.includes("/profile")
    )
      return;

    const prevParam = params?.firmId || "";
    const paramLength = prevParam?.length;
    let newPath = location?.pathname;
    const searchQuery = location?.search;

    if (newPath && !newPath.includes(firmId)) {
      newPath =
        "/" + firmId + newPath.substring(paramLength ? paramLength + 1 : 0);
    }

    newPath && navigate?.(newPath + searchQuery, { replace: true });
  };

  updateRoute = () => {
    const { location, params, currentFirm } = this.props;
    const path = location?.pathname;
    const firmId = currentFirm?._id || params?.firmId;

    menuItems(firmId).forEach(menuItem => {
      if (path?.indexOf(menuItem.route) === 0) {
        this.setState({
          menuState: { [menuItem.name]: true }
        });
      }
      menuItem.children?.forEach(subItem => {
        if (path?.indexOf(subItem.route) === 0) {
          this.setState({
            subMenuState: { [subItem.name]: true }
          });
        }
      });
    });
  };

  menuListToggle = () => {
    const firmId = this.props.params?.firmId || this.props.currentFirm?._id;
    const childrenMenus: `${MenuItemName}-${MenuItemChildrenName | ""}`[] = [];

    menuItems(firmId).forEach(item => {
      if (item.children) {
        item.children.forEach(child => {
          childrenMenus.push(`${item.name}-${child.name}`);
        });
      } else {
        childrenMenus.push(`${item.name}-`);
      }
    });
    const path = this.props.location?.pathname?.split("/")[1];
    const isSubMenu = childrenMenus.find(item =>
      path?.includes(item.split("-")[0].toLowerCase())
    );
    if (isSubMenu) {
      this.setState({
        menuState: { [isSubMenu.split("-")[0]]: true }
      });
    }
  };

  toggleDropdown = (name: MenuItemName) => {
    if (this.state.menuState?.[name]) {
      this.setState({ menuState: { [name]: false } });
    } else {
      this.setState({ menuState: { [name]: true } });
    }
  };

  toggleSubItemDropdown = (name: MenuItemChildrenName) => {
    if (this.state.subMenuState?.[name]) {
      this.setState({ subMenuState: undefined });
    } else {
      this.setState({ subMenuState: { [name]: true } });
    }
  };

  openModalHandler = (modalName: string) => {
    this.props.updateCommon?.({
      currentModal: { modalName, fetchAgain: false }
    });
  };

  toggleMenu = () => {
    this.setState({ menuShow: !this.state.menuShow });
  };

  toggleProfileMenu = () => {
    this.setState({ profileMenuShow: !this.state.profileMenuShow });
  };

  noFirmClickHandler = (route: string) => {
    const currentFirmId = this.props.currentFirm?._id;
    if (!this.state.loading) {
      if (
        this.linkRouteDirect(route) === route ||
        this.linkRouteDirect(route) === "/firms"
      )
        return;
      if (this.linkRouteDirect(route) === `/${currentFirmId}/subscriptions`) {
        this.sendExpiryNotification();
      }
    }
  };

  linkRouteDirect = (route: string) => {
    const currentFirm = this.props.currentFirm;

    if (checkIfPlanExpired(currentFirm)) {
      return `/${currentFirm?._id}/subscriptions`;
    } else if (this.props.firms?.length === 0) {
      return "/firms";
    } else {
      return route;
    }
  };

  logout = () => {
    this.props.onLogout?.();
    localStorage.removeItem("currentFirm");
    this.props.updateCommon?.({
      firms: [],
      currentFirm: undefined,
      isFirmPresent: false,
      currentModal: { modalName: "", fetchAgain: false },
      currentUser: undefined,
      rights: undefined,
      urlInfo: ""
    });
    this.props.addNotification?.(
      "Successful",
      "You have been successfully logged out.",
      "success"
    );
  };

  shrinkedMenuPages = () => {
    const pathname = this.props.location?.pathname;
    return pathname?.includes("subscription");
  };

  toShrinkPage = () => {
    if (this.shrinkedMenuPages()) {
      this.setState({ shrinkMenu: true });
    }
  };

  onShrinkMenuHover = () => {
    const toShrink = this.shrinkedMenuPages();
    if (toShrink) {
      this.setState({ shrinkMenu: !this.state.shrinkMenu });
    }
  };

  render() {
    const { currentFirm, params } = this.props;
    const firmId = currentFirm?._id || params?.firmId;

    return (
      <div>
        <div
          style={
            {
              height: `calc(100vh - ${this.state.infoFooterHeight}px)`
            } as React.CSSProperties
          }
          className="flex overflow-hidden bg-gray-100"
        >
          {/* <!-- Off-canvas menu for mobile, show/hide based on off-canvas menu state. --> */}

          <Transition.Root show={this.state.menuShow} as={Fragment}>
            <Dialog
              as="div"
              className="relative z-40 md:hidden"
              onClose={this.toggleMenu}
            >
              <Transition.Child
                as={Fragment}
                enter="transition-opacity ease-linear duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity ease-linear duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
              </Transition.Child>

              <div className="fixed inset-0 flex z-40">
                <Transition.Child
                  as={Fragment}
                  enter="transition ease-in-out duration-300 transform"
                  enterFrom="-translate-x-full"
                  enterTo="translate-x-0"
                  leave="transition ease-in-out duration-300 transform"
                  leaveFrom="translate-x-0"
                  leaveTo="-translate-x-full"
                >
                  <Dialog.Panel className="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-gray-800">
                    <Transition.Child
                      as={Fragment}
                      enter="ease-in-out duration-300"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="ease-in-out duration-300"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <div className="absolute top-0 right-0 -mr-12 pt-2">
                        <button
                          type="button"
                          className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                          onClick={this.toggleMenu}
                        >
                          <span className="sr-only">Close sidebar</span>
                          <XMarkIcon
                            className="h-6 w-6 text-white"
                            aria-hidden="true"
                          />
                        </button>
                      </div>
                    </Transition.Child>
                    <div className="flex-shrink-0 flex items-center px-4">
                      <img
                        className="h-8 w-auto"
                        src="/images/Taxpido_logo_white.png"
                        alt="TaxPido Logo"
                      />
                    </div>
                    <div className="mt-5 flex-1 max-h-[calc(100vh-7rem)] overflow-y-auto vertical-scroll">
                      <nav className="px-2 space-y-1">
                        <div className="sm:hidden mt-4 pb-4 mb-2 border-b border-white">
                          {(this.props.params?.firmId ||
                            this.props.location?.pathname === "/firms") &&
                            this.props.firms &&
                            this.props.firms?.length > 1 && (
                              <div className="w-full px-3">
                                <MultiSelect
                                  items={this.props.firms?.map(firm => {
                                    return {
                                      ...firm,
                                      name: firm.name
                                    };
                                  })}
                                  selected={{
                                    name: this.props.currentFirm?.name
                                  }}
                                  type="firms"
                                  onChange={selected =>
                                    this.onFirmChange(selected, false)
                                  }
                                  placeholder="Select Firm"
                                />
                              </div>
                            )}
                        </div>
                        {menuItems(firmId).map(menuItem =>
                          !menuItem.children.length ? (
                            <div key={menuItem.name}>
                              <Link
                                to={this.linkRouteDirect(menuItem.route)}
                                key={menuItem.name}
                                className={`group flex items-center px-2 py-2 text-sm font-medium rounded-md text-white ${
                                  this.props.location?.pathname ===
                                  menuItem.route
                                    ? "bg-gray-900"
                                    : "text-gray-300 hover:text-white hover:bg-gray-700"
                                }`}
                                onClick={() => {
                                  // this.toggleDropdown(menuItem.name);
                                  this.noFirmClickHandler(menuItem.route);
                                }}
                              >
                                <Icon
                                  name={menuItem.iconName}
                                  className={
                                    this.state.menuState?.[menuItem.name]
                                      ? "text-gray-500 mr-3 flex-shrink-0 h-5 w-5"
                                      : "text-gray-400 group-hover:text-gray-500 mr-3 flex-shrink-0 h-5 w-5"
                                  }
                                  aria-hidden="true"
                                />
                                {menuItem.name}
                              </Link>
                            </div>
                          ) : (
                            <div key={menuItem.name}>
                              <Disclosure
                                as="div"
                                key={menuItem.name}
                                className="space-y-1"
                              >
                                <Disclosure.Button
                                  as={Link}
                                  to={this.linkRouteDirect(
                                    menuItem.route ? menuItem.route : ""
                                  )}
                                  onClick={() => {
                                    // this.toggleDropdown(menuItem.name);
                                    this.noFirmClickHandler(menuItem.route);
                                  }}
                                  className="text-gray-300 w-full justify-between hover:bg-gray-700 hover:text-white group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                                >
                                  <span className="flex items-center">
                                    <Icon
                                      name={menuItem.iconName}
                                      className="mr-3 flex-shrink-0 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                                      aria-hidden="true"
                                    />
                                    {menuItem.name}
                                  </span>
                                  <ChevronDownIcon
                                    className={
                                      !this.state.menuState?.[menuItem.name]
                                        ? "text-gray-400 -rotate-90 ml-3 h-5 w-5 transform group-hover:text-gray-400 transition-colors ease-in-out duration-150"
                                        : "text-gray-300 ml-3 h-5 w-5 transform group-hover:text-gray-400 transition-colors ease-in-out duration-150"
                                    }
                                  />
                                </Disclosure.Button>
                                {this.state.menuState?.[menuItem.name] ? (
                                  <Disclosure.Panel
                                    className="space-y-1 ml-11 border-l border-gray-700"
                                    static
                                  >
                                    {menuItem.children.map(
                                      subItem => (
                                        // "children" in subItem ? (
                                        //   <div key={subItem.name}>
                                        //     <Disclosure
                                        //       as="div"
                                        //       key={subItem.name}
                                        //       className="space-y-1"
                                        //     >
                                        //       {({ open }) => (
                                        //         <div
                                        //           onClick={() =>
                                        //             this.toggleSubItemDropdown(
                                        //               subItem.name
                                        //             )
                                        //           }
                                        //         >
                                        //           <Disclosure.Button className="text-gray-300 w-full justify-between hover:bg-gray-700 hover:text-white group flex items-center px-2 py-2 text-sm font-medium rounded-md">
                                        //             <span className="flex items-center">
                                        //               {subItem.name}
                                        //             </span>
                                        //             <ChevronDownIcon
                                        //               className={
                                        //                 !this.state
                                        //                   .subMenuState?.[
                                        //                   subItem.name
                                        //                 ]
                                        //                   ? "text-gray-400 -rotate-90 ml-3 h-5 w-5 transform group-hover:text-gray-400 transition-colors ease-in-out duration-150"
                                        //                   : "text-gray-300 ml-3 h-5 w-5 transform group-hover:text-gray-400 transition-colors ease-in-out duration-150"
                                        //               }
                                        //             />
                                        //           </Disclosure.Button>
                                        //           {this.state.subMenuState?.[
                                        //             subItem.name
                                        //           ] ? (
                                        //             <Disclosure.Panel
                                        //               className="space-y-1 ml-6 border-l border-gray-700"
                                        //               static
                                        //             >
                                        //               {subItem.children.map(
                                        //                 subSubItem => (
                                        //                   <Link
                                        //                     to={this.linkRouteDirect(
                                        //                       subSubItem.route
                                        //                     )}
                                        //                     key={
                                        //                       subSubItem.name
                                        //                     }
                                        //                     className={
                                        //                       (
                                        //                         this
                                        //                           .props
                                        //                       ).location
                                        //                         .pathname ===
                                        //                       subSubItem.route
                                        //                         ? "bg-gray-900 text-white group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                                        //                         : "text-gray-300 hover:bg-gray-700 hover:text-white group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                                        //                     }
                                        //                     onClick={() =>
                                        //                       this.noFirmClickHandler(
                                        //                         menuItem.route
                                        //                       )
                                        //                     }
                                        //                   >
                                        //                     {subSubItem.name}
                                        //                   </Link>
                                        //                 )
                                        //               )}
                                        //             </Disclosure.Panel>
                                        //           ) : null}
                                        //         </div>
                                        //       )}
                                        //     </Disclosure>
                                        //   </div>
                                        // ) : (
                                        <div key={subItem.name}>
                                          <Link
                                            to={this.linkRouteDirect(
                                              subItem.route
                                            )}
                                            key={subItem.name}
                                            className={
                                              this.props.location?.pathname ===
                                              subItem.route
                                                ? "bg-gray-900 text-white group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                                                : "text-gray-300 hover:bg-gray-700 hover:text-white group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                                            }
                                            onClick={() =>
                                              this.noFirmClickHandler(
                                                menuItem.route
                                              )
                                            }
                                          >
                                            <Icon
                                              name={subItem.iconName}
                                              className={
                                                this.state.menuState?.[
                                                  menuItem.name
                                                ]
                                                  ? "text-gray-500 mr-3 flex-shrink-0 h-5 w-5"
                                                  : "text-gray-400 group-hover:text-gray-500 mr-3 flex-shrink-0 h-5 w-5"
                                              }
                                            />
                                            {subItem.name}
                                          </Link>
                                        </div>
                                      )
                                      // )
                                    )}
                                  </Disclosure.Panel>
                                ) : null}
                              </Disclosure>
                            </div>
                          )
                        )}
                        <BugReportLink />
                        <ExtensionDownloadLink />
                      </nav>
                    </div>
                    <UpdateLogsLink />
                  </Dialog.Panel>
                </Transition.Child>
                <div className="flex-shrink-0 w-14" aria-hidden="true">
                  {/* Dummy element to force sidebar to shrink to fit close icon */}
                </div>
              </div>
            </Dialog>
          </Transition.Root>

          {/* <!-- Static sidebar for desktop --> */}
          <>
            {this.shrinkedMenuPages() && (
              <div
                className="hidden md:flex md:flex-col md:gap-5 p-4 bg-gray-800 relative z-40"
                onMouseEnter={this.onShrinkMenuHover}
              >
                <div className="h-12 mb-1">
                  <Icon name="point-right" className="h-6 w-6 text-gray-400" />
                </div>
                {menuItems(firmId).map((item, index) => (
                  <div key={index + item.iconName}>
                    <Icon
                      name={item.iconName}
                      className={
                        "text-gray-400 group-hover:text-gray-500 mr-3 flex-shrink-0 h-5 w-5"
                      }
                      aria-hidden="true"
                    />
                  </div>
                ))}
                <BugReportLink onlyIcon={this.shrinkedMenuPages()} />
                <ExtensionDownloadLink onlyIcon={this.shrinkedMenuPages()} />
                <UpdateLogsLink onlyIcon={this.shrinkedMenuPages()} />
              </div>
            )}
            {!this.state.shrinkMenu && (
              <div
                className={`${
                  this.shrinkedMenuPages()
                    ? "hidden md:flex md:flex-shrink-0 fixed inset-0 h-full w-fit z-50"
                    : "hidden md:flex md:flex-shrink-0"
                }`}
                onMouseLeave={this.onShrinkMenuHover}
              >
                <div className="flex flex-col w-64 bg-gray-800">
                  {/* <!-- Sidebar component, swap this element with another sidebar if you like --> */}
                  <div className="grid grid-rows-[4rem,auto,3rem] min-h-screen relative">
                    <div className="flex items-center h-16 flex-shrink-0 px-4 bg-gray-900">
                      <img
                        className="h-8 m-auto w-auto"
                        src="/images/Taxpido_logo_white.png"
                        alt="TaxPido Logo"
                      />
                    </div>
                    <div className="flex-1 flex flex-col overflow-y-auto vertical-scroll">
                      <nav className="flex-1 px-2 py-4 space-y-1">
                        {menuItems(firmId).map(menuItem =>
                          !menuItem.children.length ? (
                            <div key={menuItem.name}>
                              <Link
                                to={this.linkRouteDirect(menuItem.route)}
                                key={menuItem.name}
                                className={`group flex items-center px-2 py-2 text-sm font-medium rounded-md text-white ${
                                  this.props.location?.pathname ===
                                  menuItem.route
                                    ? "bg-gray-900"
                                    : "text-gray-300 hover:text-white hover:bg-gray-700"
                                }`}
                                onClick={() => {
                                  // this.toggleDropdown(menuItem.name);
                                  this.noFirmClickHandler(menuItem.route);
                                }}
                              >
                                <Icon
                                  name={menuItem.iconName}
                                  className={
                                    this.state.menuState?.[menuItem.name]
                                      ? "text-gray-500 mr-3 flex-shrink-0 h-5 w-5"
                                      : "text-gray-400 group-hover:text-gray-500 mr-3 flex-shrink-0 h-5 w-5"
                                  }
                                  aria-hidden="true"
                                />
                                {menuItem.name}
                              </Link>
                            </div>
                          ) : (
                            <div key={menuItem.name}>
                              <Disclosure
                                as="div"
                                key={menuItem.name}
                                className="space-y-1"
                              >
                                <Disclosure.Button
                                  as={Link}
                                  to={this.linkRouteDirect(
                                    menuItem.route ? menuItem.route : ""
                                  )}
                                  onClick={() => {
                                    // this.toggleDropdown(menuItem.name);
                                    this.noFirmClickHandler(menuItem.route);
                                  }}
                                  className="text-gray-300 w-full justify-between hover:bg-gray-700 hover:text-white group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                                >
                                  <span className="flex items-center">
                                    <Icon
                                      name={menuItem.iconName}
                                      className="mr-3 flex-shrink-0 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                                      aria-hidden="true"
                                    />
                                    {menuItem.name}
                                  </span>
                                  <ChevronDownIcon
                                    className={
                                      !this.state.menuState?.[menuItem.name]
                                        ? "text-gray-400 -rotate-90 ml-3 h-5 w-5 transform group-hover:text-gray-400 transition-colors ease-in-out duration-150"
                                        : "text-gray-300 ml-3 h-5 w-5 transform group-hover:text-gray-400 transition-colors ease-in-out duration-150"
                                    }
                                  />
                                </Disclosure.Button>
                                {this.state.menuState?.[menuItem.name] ? (
                                  <Disclosure.Panel
                                    className="space-y-1 ml-11 border-l border-gray-700"
                                    static
                                  >
                                    {menuItem.children.map(
                                      subItem => (
                                        // "children" in subItem ? (
                                        //   <div key={subItem.name}>
                                        //     <Disclosure
                                        //       as="div"
                                        //       key={subItem.name}
                                        //       className="space-y-1"
                                        //     >
                                        //       {({ open }) => (
                                        //         <div
                                        //           onClick={() =>
                                        //             this.toggleSubItemDropdown(
                                        //               subItem.name
                                        //             )
                                        //           }
                                        //         >
                                        //           <Disclosure.Button className="text-gray-300 w-full justify-between hover:bg-gray-700 hover:text-white group flex items-center px-2 py-2 text-sm font-medium rounded-md">
                                        //             <span className="flex items-center">
                                        //               <Icon
                                        //                 name={
                                        //                   subItem.iconName
                                        //                 }
                                        //                 className="mr-3 flex-shrink-0 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                                        //                 aria-hidden="true"
                                        //               />
                                        //               {subItem.name}
                                        //             </span>
                                        //             <ChevronDownIcon
                                        //               className={
                                        //                 !this.state
                                        //                   .subMenuState?.[
                                        //                   subItem.name
                                        //                 ]
                                        //                   ? "text-gray-400 -rotate-90 ml-3 h-5 w-5 transform group-hover:text-gray-400 transition-colors ease-in-out duration-150"
                                        //                   : "text-gray-300 ml-3 h-5 w-5 transform group-hover:text-gray-400 transition-colors ease-in-out duration-150"
                                        //               }
                                        //             />
                                        //           </Disclosure.Button>
                                        //           {this.state.subMenuState?.[
                                        //             subItem.name
                                        //           ] ? (
                                        //             <Disclosure.Panel
                                        //               className="space-y-1 ml-6 border-l border-gray-700"
                                        //               static
                                        //             >
                                        //               {subItem.children.map(
                                        //                 subSubItem => (
                                        //                   <Link
                                        //                     to={this.linkRouteDirect(
                                        //                       subSubItem.route
                                        //                     )}
                                        //                     key={
                                        //                       subSubItem.name
                                        //                     }
                                        //                     className={
                                        //                       (
                                        //                         this
                                        //                           .props
                                        //                       ).location
                                        //                         .pathname ===
                                        //                       subSubItem.route
                                        //                         ? "bg-gray-900 text-white group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                                        //                         : "text-gray-300 hover:bg-gray-700 hover:text-white group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                                        //                     }
                                        //                     onClick={() =>
                                        //                       this.noFirmClickHandler(
                                        //                         menuItem.route
                                        //                       )
                                        //                     }
                                        //                   >
                                        //                     {subSubItem.name}
                                        //                   </Link>
                                        //                 )
                                        //               )}
                                        //             </Disclosure.Panel>
                                        //           ) : null}
                                        //         </div>
                                        //       )}
                                        //     </Disclosure>
                                        //   </div>
                                        // ) : (
                                        <div key={subItem.name}>
                                          <Link
                                            to={this.linkRouteDirect(
                                              subItem.route
                                            )}
                                            key={subItem.name}
                                            className={
                                              this.props.location?.pathname ===
                                              subItem.route
                                                ? "bg-gray-900 text-white group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                                                : "text-gray-300 hover:bg-gray-700 hover:text-white group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                                            }
                                            onClick={() =>
                                              this.noFirmClickHandler(
                                                menuItem.route
                                              )
                                            }
                                          >
                                            <Icon
                                              name={subItem.iconName}
                                              className={
                                                this.state.menuState?.[
                                                  menuItem.name
                                                ]
                                                  ? "text-gray-500 mr-3 flex-shrink-0 h-5 w-5"
                                                  : "text-gray-400 group-hover:text-gray-500 mr-3 flex-shrink-0 h-5 w-5"
                                              }
                                            />
                                            {subItem.name}
                                          </Link>
                                        </div>
                                      )
                                      // )
                                    )}
                                  </Disclosure.Panel>
                                ) : null}
                              </Disclosure>
                            </div>
                          )
                        )}
                        <BugReportLink onlyIcon={this.state.shrinkMenu} />
                        <ExtensionDownloadLink
                          onlyIcon={this.state.shrinkMenu}
                        />
                      </nav>
                    </div>
                    <UpdateLogsLink onlyIcon={this.state.shrinkMenu} />
                  </div>
                </div>
              </div>
            )}
          </>

          <div className="flex flex-col w-0 flex-1 overflow-hidden">
            <div className="relative z-10 flex-shrink-0 flex h-16 bg-white shadow">
              <button
                className="px-4 border-r border-gray-200 text-gray-500 focus:outline-none md:hidden"
                onClick={this.toggleMenu}
              >
                <span className="sr-only">Open sidebar</span>
                <Icon name="outline/menu-alt-2" className="h-6 w-6" />
              </button>
              <div className="flex-1 px-4 flex justify-between">
                <div className="w-full flex items-center justify-between gap-4">
                  <div className="flex items-center gap-4">
                    {(this.props.params?.firmId ||
                      this.props.location?.pathname === "/firms") &&
                      this.props.firms &&
                      this.props.firms?.length > 1 && (
                        <div className="hidden sm:block ml-4 w-48 lg:w-64 grow">
                          <MultiSelect
                            items={this.props.firms?.map(firm => {
                              return {
                                ...firm,
                                name: firm.name
                              };
                            })}
                            selected={{
                              name: this.props.currentFirm?.name
                            }}
                            type="firms"
                            onChange={selected =>
                              this.onFirmChange(selected, false)
                            }
                            placeholder="Select Firm"
                          />
                        </div>
                      )}

                    <SearchNavigation
                      openModalHandler={this.openModalHandler}
                    />
                  </div>
                  <div className="flex items-center gap-4 md:gap-8">
                    {/* <!-- Profile dropdown --> */}
                    <Menu as="div" className="inline-block relative">
                      <Menu.Button>
                        <div
                          className="max-w-xs bg-white flex items-center text-sm rounded-full focus:outline-none"
                          id="user-menu-button"
                          aria-expanded="false"
                          aria-haspopup="true"
                          onClick={this.toggleProfileMenu}
                        >
                          <span className="sr-only">Open user menu</span>
                          <span className="w-8 h-8 p-2 rounded-full cursor-pointer inline-flex items-center justify-center bg-gray-700 text-white mr-3 text-sm xs:text-base font-bold">
                            {this.props?.currentUser?.shortname}
                          </span>
                        </div>
                      </Menu.Button>

                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <div>
                          <Menu.Items className="origin-top-right absolute right-0 mt-5 w-56 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                            <div className="py-1">
                              <Menu.Item>
                                <Link
                                  to={`/profile/general`}
                                  className="flex items-center w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                                  role="menuitem"
                                  tabIndex={-1}
                                  id="user-menu-item-0"
                                >
                                  <Icon
                                    name="outline/user"
                                    className="h-5 w-5 mr-2 text-gray-700"
                                  />
                                  <span>Your Profile</span>
                                </Link>
                              </Menu.Item>
                              <Menu.Item>
                                <Link
                                  to="/firms"
                                  className="flex items-center w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                                  role="menuitem"
                                  tabIndex={-1}
                                  id="user-menu-item-0"
                                >
                                  <Icon
                                    name="building-office-2"
                                    className="h-5 w-5 mr-2 text-gray-700"
                                  />
                                  <span>Your Firms</span>
                                </Link>
                              </Menu.Item>
                              <Menu.Item>
                                <Link
                                  to={this.linkRouteDirect(
                                    `/${this.props.currentFirm?._id}/subscriptions`
                                  )}
                                  className="flex items-center w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                                  role="menuitem"
                                  tabIndex={-1}
                                  id="user-menu-item-0"
                                >
                                  <Icon
                                    name="outline/card"
                                    className="h-5 w-5 mr-2 text-gray-700"
                                  />
                                  <span>Subscription</span>
                                </Link>
                              </Menu.Item>
                              <Menu.Item>
                                <button
                                  className="flex items-center w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                                  role="menuitem"
                                  tabIndex={-1}
                                  id="user-menu-item-0"
                                  onClick={this.logout}
                                >
                                  <Icon
                                    name="outline/logout"
                                    className="h-5 w-5 mr-2 text-gray-700"
                                  />
                                  <span>Sign out</span>
                                </button>
                              </Menu.Item>
                            </div>
                          </Menu.Items>
                        </div>
                      </Transition>
                    </Menu>
                  </div>
                </div>
              </div>
            </div>

            <main className="flex-1 relative overflow-y-auto overflow-x-hidden focus:outline-none">
              <div className="py-6">
                <div
                  className={`mx-auto ${
                    this.shrinkedMenuPages()
                      ? "w-full max-w-[95rem] px-4 sm:px-6"
                      : "max-w-7xl px-4 sm:px-6 md:px-8"
                  }`}
                >
                  <ScrollToBottom
                    id="dashboard-page"
                    bottomOffset={this.state.infoFooterHeight}
                  >
                    <ErrorBoundary type="dashboard">
                      {this.state.planExpiryMessage && (
                        <Message
                          message={this.state.planExpiryMessage}
                          fullWidth={this.state.shrinkMenu}
                          closeIcon={
                            !checkIfPlanExpired(this.props.currentFirm)
                          }
                          color="red"
                          onClick={() => this.moveToSubscriptionPage()}
                          onClose={() =>
                            this.setState({ planExpiryMessage: "" })
                          }
                        />
                      )}
                      {this.props.children}
                    </ErrorBoundary>
                  </ScrollToBottom>
                </div>
              </div>
            </main>
          </div>
        </div>
        <div ref={this.infoFooterRef} className={`fixed md:relative`}>
          <MessageBar
            showInfoFooter={this.state.showInfoFooter}
            showHideInfoFooter={this.showHideInfoFooter}
            infoFooterHeight={this.state.infoFooterHeight}
          />
        </div>
      </div>
    );
  }
}

export default compose(
  connector,
  withRouter
)(Dashboard) as React.ComponentType<DashboardProps>;
