import { Component } from "react";
import Icon from "../components/Icon";
import MessageBar from "../components/MessageBar";

export default class Maintenance extends Component {
  componentDidMount(): void {
    document.title = "Under Maintenance - TaxPido Login Tool";
  }
  render() {
    return (
      <>
        <div className="min-h-screen grow py-16 px-6 sm:py-24 grid place-items-center lg:px-8">
          <div className="mx-auto max-w-max">
            <div className="sm:flex text-center sm:text-left">
              <Icon
                name="outline/settings"
                className="w-24 text-gray-300 animate-[spin_15s_linear_infinite] fixed top-20 right-20"
              />
              <Icon
                name="outline/settings"
                className="w-20 text-gray-300 rotate-180 animate-[spin_8s_linear_infinite] fixed top-[4.5rem] right-40"
              />
              <div>
                <h1 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                  The Website is currently under maintenance
                </h1>
                <p className="mt-3 text-base text-gray-600">
                  Please check back later after the maintenance is complete.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="hidden">
          <MessageBar
            showInfoFooter={false}
            showHideInfoFooter={() => {}}
            infoFooterHeight={0}
          />
        </div>
      </>
    );
  }
}
