import {
  ChangeEvent,
  ComponentType,
  Fragment,
  MouseEvent,
  useEffect,
  useState
} from "react";
// Import to Display skeleton while loading data
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { connect, ConnectedProps } from "react-redux";
import { withRouter, WithRouterProps } from "../../helpers/withRouter";
import { compose } from "redux";
// Redux Notify
import { ADD_NOTIFICATION, UPDATE_COMMON } from "../../store/types";
import Dashboard, {
  getAllUsersList,
  getSelfDetails
} from "../../components/Dashboard";
import agent from "../../agent";
import { Tab } from "@headlessui/react";
import Icon from "../../components/Icon";
import { Link } from "react-router-dom";
import TagManager from "react-gtm-module";
import { AppDispatch, RootState } from "../../store";
import { CommonState } from "../../store/reducers/common";
import { NotificationType } from "../../store/reducers/notification";

const tagManagerArgs = {
  dataLayer: {
    userId: "001",
    userProject: "TaxPido Login Tool",
    page: "Profile"
  },
  dataLayerName: "PageDataLayer"
};

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

//Redux mapping
const mapStateToProps = (state: RootState) => ({
  ...state.notification,
  ...state.common
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  updateCommon: (payload: Partial<CommonState>) =>
    dispatch({ type: UPDATE_COMMON, payload }),
  addNotification: (title: string, message: string, type: NotificationType) =>
    dispatch({
      type: ADD_NOTIFICATION,
      payload: {
        title,
        message,
        type
      }
    })
});

const skeleton = (row: number, col: number) => {
  return [...Array(row)].map((item, index) => (
    <div
      key={index}
      className="py-4 sm:grid sm:grid-cols-3 items-center sm:gap-4 sm:py-5"
    >
      {[...Array(col)].map((item, index) => (
        <Skeleton key={index} />
      ))}
    </div>
  ));
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = Partial<PropsFromRedux & WithRouterProps<{ tab: string }>>;

const tabs = ["General" /*  "Password", */];

type UpdateType = "" | "fullName" | "shortName" | "email" | "phone";

const profileTemp = { fullName: "", shortName: "", email: "", phone: "" };

function Profile(props: Props) {
  TagManager.dataLayer(tagManagerArgs);

  const [loading, setLoading] = useState(false);
  const [passwordError, setPasswordError] = useState(false);

  const [currentTab, setCurrentTab] = useState(
    tabs.indexOf(tabs.filter(tab => tab.toLowerCase() === props.params?.tab)[0])
  );

  useEffect(() => {
    setCurrentTab(
      tabs.indexOf(
        tabs.filter(tab => tab.toLowerCase() === props.params?.tab)[0]
      )
    ); // eslint-disable-next-line
  }, [props.params?.tab]);

  const setTabChange = (index: number) => {
    setCurrentTab(index);
  };

  useEffect(() => {
    document.title = "Profile - TaxPido Login Tool";
  }, []);

  const [forUpdate, setForUpdate] = useState<UpdateType>("");

  const handleUpdateClick = (e: MouseEvent<HTMLButtonElement>) => {
    const id = (e.target as HTMLButtonElement).id as UpdateType;
    id === forUpdate ? update(id) : setForUpdate(id);
  };

  const cancelUpdate = () => setForUpdate("");

  useEffect(() => {
    const onKeyPress = (e: KeyboardEvent) => {
      if (e.key === "Enter" && forUpdate) {
        update(forUpdate);
      }
    };
    document.addEventListener("keydown", onKeyPress);
    return () => {
      document.removeEventListener("keydown", onKeyPress);
    };
  }, [forUpdate]);

  const [profile, setProfile] = useState(profileTemp);

  useEffect(() => {
    setProfile({
      fullName: props?.currentUser?.name || "",
      shortName: props?.currentUser?.shortname || "",
      email: props?.currentUser?.email || "",
      phone: props?.currentUser?.mobileNumber || ""
    });
  }, [props?.currentUser]);

  const onProfileChange = (e: ChangeEvent<HTMLInputElement>) => {
    setProfile({ ...profile, [e.target.name]: e.target.value });
  };

  const updateName = () => {
    const firmId = props.currentFirm?._id;

    if (!firmId) {
      return props.addNotification?.("Error", "Firm Id not found", "danger");
    }

    setLoading(true);
    agent.User.updateUserName(profile.fullName)
      .then(res => {
        setLoading(false);
        getSelfDetails(firmId, props.updateCommon, props.addNotification);
        getAllUsersList(firmId, props.updateCommon, props.addNotification);
        setForUpdate("");
        props.addNotification?.(
          "Success",
          "Name updated successfully",
          "success"
        );
      })
      .catch(err => {
        setLoading(false);
        setForUpdate("");
        props.addNotification?.("Error", "Error in updating name", "danger");
      });
  };

  const updateShortName = () => {
    // setLoading(true);
    // agent.User.updateUserShortName(profile.shortName)
    //   .then(res => {
    //     setLoading(false);
    //     getAllUsersList(
    //       props.currentFirm?._id,
    //       props.updateCommon,
    //       props.addNotification
    //     );
    //     setForUpdate("");
    //     props.addNotification?.(
    //       "Success",
    //       "Short name updated successfully",
    //       "success"
    //     );
    //   })
    //   .catch(err => {
    //     setLoading(false);
    //     setForUpdate("");
    //     props.addNotification?.(
    //       "Error",
    //       "Error in updating short name",
    //       "danger"
    //     );
    //   });
  };

  function update(data: UpdateType) {
    switch (data) {
      case "fullName":
        return updateName();
      case "shortName":
        return updateShortName();
      default:
        return;
    }
  }

  const passwordsTemp = {
    oldPassword: "",
    newPassword: "",
    confirmPassword: ""
  };

  const [password, setPassword] = useState(passwordsTemp);

  const onPasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPassword({ ...password, [e.target.name]: e.target.value });
  };

  return (
    <Dashboard>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
        <h1 className="text-2xl font-semibold text-gray-900">Settings</h1>
      </div>
      <Tab.Group selectedIndex={currentTab} onChange={setTabChange}>
        <div className="max-w-7xl mx-4 sm:mx-6 md:mx-8 bg-white mt-6">
          <div className="px-4 sm:px-6 md:px-8">
            <div className="block pt-2">
              <div className="border-b border-gray-200">
                <nav className="-mb-px flex space-x-8 overflow-x-hidden">
                  <Tab.List
                    as="ul"
                    id="tab-scroll"
                    className="flex gap-x-4 overflow-x-auto"
                  >
                    {tabs.map(tab => (
                      <Tab as={Fragment} key={tab}>
                        {({ selected }) => (
                          <Link
                            to={`/${
                              props.currentFirm?._id
                            }/profile/${tab.toLowerCase()}`}
                            className={classNames(
                              selected
                                ? "border-indigo-500 text-indigo-600"
                                : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                              "whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm cursor-pointer"
                            )}
                          >
                            {tab}
                          </Link>
                        )}
                      </Tab>
                    ))}
                  </Tab.List>
                </nav>
              </div>
            </div>
            <Tab.Panels>
              <Tab.Panel>
                {/* Description list with inline editing */}
                <div className="mt-8 divide-y divide-gray-200">
                  <div className="space-y-1">
                    <h3 className="text-lg font-medium leading-6 text-gray-900">
                      Profile
                    </h3>
                  </div>
                  {loading ? (
                    <div className="mt-6">
                      <div className="divide-y divide-gray-200">
                        {skeleton(5, 3)}
                      </div>
                    </div>
                  ) : (
                    <div className="mt-6">
                      <dl className="divide-y divide-gray-200">
                        {Object.entries(profile).map(([key, value]) => (
                          <div
                            key={key}
                            className="py-4 sm:grid sm:grid-cols-3 items-center sm:gap-4 sm:py-5"
                          >
                            <dt className="text-sm font-medium text-gray-500 capitalize">
                              {key === "fullName"
                                ? "Full Name"
                                : key === "shortName"
                                ? "Short Name"
                                : key}
                            </dt>
                            <dd className="mt-1 flex items-center gap-x-8 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                              <span className="flex-grow">
                                {forUpdate === key ? (
                                  <input
                                    type="text"
                                    name={key}
                                    autoFocus={forUpdate === key}
                                    value={value}
                                    onChange={onProfileChange}
                                    className="appearance-none block w-full lg:w-1/2 border border-gray-300 rounded-md shadow-sm text-sm placeholder-gray-400 focus:outline-none  focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                  />
                                ) : (
                                  <span className="inline-block px-3 py-2">
                                    {value}
                                  </span>
                                )}
                              </span>
                              <span className="ml-4 flex-shrink-0 flex items-center gap-4 sm:gap-8">
                                {forUpdate === key && (
                                  <button
                                    type="button"
                                    onClick={cancelUpdate}
                                    className="px-2 py-1 rounded-md bg-white font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                  >
                                    Cancel
                                  </button>
                                )}
                                {key === "fullName" && (
                                  <button
                                    type="button"
                                    id={key}
                                    onClick={handleUpdateClick}
                                    className="px-2 py-1 rounded-md bg-white font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                  >
                                    Update
                                  </button>
                                )}
                              </span>
                            </dd>
                          </div>
                        ))}
                        {/* <div className="py-4 sm:grid sm:grid-cols-3 items-center sm:gap-4 sm:py-5 sm:pt-5">
                          <dt className="text-sm font-medium text-gray-500">
                            Password
                          </dt>
                          <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                            <span className="flex-grow"></span>
                            <span className="ml-4 flex-shrink-0">
                              <Link
                                to={`/${
                                  (props).currentFirm?._id
                                }/profile/password`}
                                type="button"
                                className="px-2 py-1 rounded-md bg-white font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                              >
                                Reset Password
                              </Link>
                            </span>
                          </dd>
                        </div> */}
                      </dl>
                    </div>
                  )}
                </div>
              </Tab.Panel>
              {/* <Tab.Panel>
                <div className="mt-8 divide-y divide-gray-200">
                  <div className="space-y-1">
                    <h3 className="text-lg font-medium leading-6 text-gray-900">
                      Reset Password
                    </h3>
                  </div>
                  <div className="mt-6">
                    <dl className="divide-y divide-gray-200">
                      <div className="py-4 grid grid-cols-2  items-center sm:gap-4 sm:py-5">
                        <dt className="text-sm font-medium text-gray-500">
                          Enter Your Current Password
                        </dt>
                        <dd className="mt-1 flex justify-between text-sm text-gray-900 sm:mt-0">
                          <span className="flex-grow">
                            <input
                              type="password"
                              name="oldPassword"
                              value={password.oldPassword}
                              onChange={onPasswordChange}
                              className={
                                passwordError
                                  ? "block w-full pr-10 border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:border-red-500 sm:text-sm rounded-md"
                                  : "appearance-none block px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none  focus:ring-indigo-500 focus:border-indigo-500 w-full sm:text-sm"
                              }
                            />
                          </span>
                        </dd>
                      </div>
                      <div className="py-4 grid grid-cols-2  items-center sm:gap-4 sm:py-5">
                        <dt className="text-sm font-medium text-gray-500">
                          Enter Your New Password
                        </dt>
                        <dd className="mt-1 flex justify-between text-sm text-gray-900 sm:mt-0">
                          <span className="flex-grow">
                            <input
                              type="password"
                              name="newPassword"
                              value={password.newPassword}
                              onChange={onPasswordChange}
                              className={
                                passwordError
                                  ? "block w-full pr-10 border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:border-red-500 sm:text-sm rounded-md"
                                  : "appearance-none block px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none  focus:ring-indigo-500 focus:border-indigo-500 w-full sm:text-sm"
                              }
                            />
                          </span>
                        </dd>
                      </div>
                      <div className="py-4 grid grid-cols-2 items-center sm:gap-4 sm:py-5 sm:pt-5">
                        <dt className="text-sm font-medium text-gray-500">
                          Re-Enter Your New Password
                        </dt>
                        <dd className="mt-1 flex justify-between text-sm text-gray-900 sm:mt-0">
                          <span className="flex-grow">
                            <input
                              type="password"
                              name="confirmPassword"
                              value={password.confirmPassword}
                              onChange={onPasswordChange}
                              className={
                                passwordError
                                  ? "block w-full pr-10 border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:border-red-500 sm:text-sm rounded-md"
                                  : "appearance-none block px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none  focus:ring-indigo-500 focus:border-indigo-500 w-full sm:text-sm"
                              }
                            />
                          </span>
                        </dd>
                      </div>
                    </dl>
                  </div>
                  <div className="py-6 sm:flex sm:justify-end">
                    <button
                      type="button"
                      className="w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm py-2  text-base bg-white font-medium text-gray-700 hover:bg-gray-50 focus:outline-none sm:w-32 sm:text-sm"
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      disabled={loading}
                      className="mt-3 sm:ml-4 w-full inline-flex items-center justify-center rounded-md border border-transparent border-gray-300 shadow-sm py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none sm:mt-0 sm:w-32 sm:text-sm"
                    >
                      <span className="w-full flex justify-end">
                        {loading ? (
                          <Icon name="loading" className="mr-2" />
                        ) : null}
                      </span>
                      <span>Reset</span>
                      <span className="w-full"></span>
                    </button>
                  </div>
                </div>
              </Tab.Panel> */}
            </Tab.Panels>
          </div>
        </div>
      </Tab.Group>
    </Dashboard>
  );
}

export default compose<ComponentType<Props>>(connector, withRouter)(Profile);
