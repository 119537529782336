import { ChangeEvent, Component, ComponentType, FormEvent } from "react";
import { compose } from "redux";
import { connect, ConnectedProps } from "react-redux";
import { RootState, AppDispatch } from "../../store";
import { CommonState } from "../../store/reducers/common";
import { NotificationType } from "../../store/reducers/notification";
import { ADD_NOTIFICATION, UPDATE_COMMON } from "../../store/types";
import { withRouter, WithRouterProps } from "../../helpers/withRouter";

import TagManager from "react-gtm-module";

import agent from "../../agent";
import { validEmail } from "../../helpers/regex";
import { UserRightsName } from "../../constants/defaultUserRights";

import Icon from "../../components/Icon";
import Dashboard, { getUserRights } from "../../components/Dashboard";
import { PageTypeParams } from "../../helpers/types";

const tagManagerArgs = {
  dataLayer: {
    userId: "001",
    userProject: "TaxPido",
    page: "Add Edit User"
  },
  dataLayerName: "PageDataLayer"
};

const mapStateToProps = (state: RootState) => ({
  ...state.notification,
  ...state.common
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  addNotification: (title: string, message: string, type: NotificationType) =>
    dispatch({ type: ADD_NOTIFICATION, payload: { title, message, type } }),
  updateCommon: (payload: Partial<CommonState>) =>
    dispatch({ type: UPDATE_COMMON, payload })
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const userRole = [
  { id: "admin", title: "Admin" },
  { id: "other", title: "Other" }
] as const;

export type UserRole = (typeof userRole)[number]["id"] | "Super Admin";

// this adds "self-rights" to the pageType params
type PageTypeParamsWithView = PageTypeParams | { pageType?: "self-rights" };

type Props = Partial<
  PropsFromRedux & WithRouterProps<PageTypeParamsWithView>
> & {};

type State = {
  loading: boolean;
  email: string;
  userRole: UserRole | undefined;
};

class AddEditViewUser extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      loading: false,
      email:
        props.params?.pageType === "self-rights"
          ? props.currentUser?.email || ""
          : props.params?.pageType === "edit"
          ? props.editUser?.email || ""
          : "",
      userRole:
        props.params?.pageType === "self-rights"
          ? props.currentUser?.role === "Super Admin"
            ? "admin"
            : props.currentUser?.role
          : props.params?.pageType === "edit"
          ? props.editUser?.role
          : "admin"
    };
  }

  noRightNotification = (type: UserRightsName) => {
    this.props.addNotification?.(
      "Rights Not Available",
      `You do not have rights to ${type} users`,
      "warn"
    );
  };

  pageType = this.props.params?.pageType;
  userId =
    this.pageType === "self-rights"
      ? this.props.currentUser?._id
      : this.pageType === "edit"
      ? this.props.editUser?._id
      : undefined;

  componentDidMount() {
    document.title = "Add Users - TaxPido Login Tool";

    if (this.props.rights) {
      this.pageType === "add" &&
        !this.props.rights?.userRights.create &&
        this.noRightNotification("create");

      this.pageType === "edit" &&
        !this.props.rights?.userRights.edit &&
        this.noRightNotification("edit");
    }
  }

  componentWillUnmount() {
    this.pageType === "edit" &&
      this.props.updateCommon?.({ editUser: undefined });
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (this.pageType === "edit" && !this.userId) {
      this.props.navigate?.(`/${this.props.params?.firmId}/user/list`, {
        replace: true
      });
    }
  }

  addEditUser = (e: FormEvent) => {
    e.preventDefault();
    if (
      this.props.rights &&
      this.pageType === "add" &&
      !this.props.rights?.userRights.create
    ) {
      return this.noRightNotification("create");
    }

    const { email, userRole } = this.state;

    const isEmailValid = validEmail.test(email);
    const workSpaceId = this.props.currentFirm?._id;

    if (email === "") {
      return this.props.addNotification?.(
        "Empty Email Field",
        "Email Field is Required!.",
        "danger"
      );
    } else if (!isEmailValid) {
      return this.props.addNotification?.(
        "Incorrect Email",
        "Please enter a valid Email to proceed.",
        "danger"
      );
    }

    if (!workSpaceId) return;

    if (!userRole)
      return this.props.addNotification?.(
        "User Role",
        "Please select a user role to proceed.",
        "danger"
      );

    this.setState({ loading: true });

    const API = this.pageType === "add" ? "addUser" : "editUser";

    agent.User[API](workSpaceId, email, userRole)
      .then(response => {
        console.log({ response });

        this.pageType === "add"
          ? this.props.addNotification?.(
              "Invitation sent",
              "Successfully sent an invitation.",
              "success"
            )
          : this.props.addNotification?.(
              "User Rights Updated",
              "Successfully updated users rights.",
              "success"
            );
        this.props.navigate?.(`/${workSpaceId}/user/list`);
      })
      .catch(err => {
        this.props.addNotification?.(
          "Failed to add the user",
          err?.response?.data?.message || err?.message || err,
          "danger"
        );
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  };

  cancelHandler = () => {
    const firmId = this.props.params?.firmId || this.props.currentFirm?._id;
    this.props.navigate?.(`/${firmId}/user/list`);
  };

  handleEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
    this.setState({ email: event.target.value.trim() });
  };

  userRoleHandler = (userRole: UserRole) => {
    this.setState({ userRole });
  };

  render() {
    TagManager.dataLayer(tagManagerArgs);

    return (
      <Dashboard>
        <div className="w-full mx-auto px-4 sm:px-6 md:px-8 gstadd">
          <div className="mt-5 md:mt-0 md:col-span-2">
            <form onSubmit={this.addEditUser}>
              <div className="shadow sm:rounded-md sm:overflow-hidden">
                <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                  <h3 className="text-lg font-medium leading-6 text-gray-900">
                    {this.pageType === "add"
                      ? "Add User"
                      : this.pageType === "self-rights"
                      ? "Your Rights"
                      : "Update User Rights"}
                  </h3>
                  <div className="grid grid-cols-3 gap-6">
                    <div className="col-span-3 lg:col-span-2">
                      <label
                        htmlFor="email"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Email <span className="text-red-600">*</span>
                      </label>
                      <div className="mt-1 flex rounded-md shadow-sm">
                        <input
                          id="email"
                          name="email"
                          type="email"
                          placeholder="Enter User's Email"
                          readOnly={
                            this.state.loading ||
                            this.pageType === "self-rights"
                          }
                          value={this.state.email}
                          onChange={this.handleEmailChange}
                          className="focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-md sm:text-sm border-gray-300"
                        />
                      </div>
                    </div>
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      User Role
                    </label>
                    <fieldset className="mt-2">
                      <legend className="sr-only">User Role</legend>
                      <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
                        {userRole.map(user => (
                          <div key={user.id} className="flex items-center">
                            <input
                              id={user.id}
                              name="user-role"
                              type="radio"
                              checked={user.id === this.state.userRole}
                              className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-400 cursor-pointer"
                              onChange={() => this.userRoleHandler(user.id)}
                              disabled={
                                this.state.loading ||
                                this.pageType === "self-rights"
                              }
                            />
                            <label
                              htmlFor={user.id}
                              className="ml-3 block text-sm font-medium text-gray-700 cursor-pointer"
                            >
                              {user.title}
                            </label>
                          </div>
                        ))}
                      </div>
                    </fieldset>
                  </div>
                  <div>
                    {this.state.userRole === "admin" && (
                      <div className="mt-4 text-sm leading-5 text-gray-500">
                        Admin can do all the things except deleting the firm. We
                        recommended you to give this access to your managers.
                      </div>
                    )}
                    {this.state.userRole === "other" && (
                      <div className="mt-4 text-sm leading-5 text-gray-500">
                        Other can do all the things except deleting the firm,
                        making changes in user rights or exporting of client
                        from the system. We recommended you to give this access
                        to your employees.
                      </div>
                    )}
                  </div>
                </div>
                <div className="px-4 py-3 bg-gray-50 sm:px-6 sm:flex sm:justify-end">
                  <button
                    type="button"
                    className="w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm py-2  text-base bg-white font-medium text-gray-700 hover:bg-gray-50 focus:outline-none sm:w-32 sm:text-sm"
                    onClick={this.cancelHandler}
                  >
                    Cancel
                  </button>
                  {this.pageType !== "self-rights" && (
                    <button
                      type="button"
                      className={
                        "mt-3 sm:ml-4 w-full inline-flex items-center justify-center rounded-md border border-transparent border-gray-300 shadow-sm py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none sm:mt-0 sm:w-32 sm:text-sm disabled:cursor-not-allowed disabled:bg-indigo-300"
                      }
                      onClick={this.addEditUser}
                      disabled={
                        this.state.loading ||
                        (!this.props?.rights?.userRights.create &&
                          !this.props?.rights?.userRights.edit)
                      }
                    >
                      <span className="w-full flex justify-end">
                        {this.state.loading ? (
                          <Icon name="loading" className="mr-2 w-4 h-4" />
                        ) : this.props?.rights?.userRights.create ||
                          this.props?.rights?.userRights.edit ? (
                          <Icon name="add" className="mr-2 w-4 h-4" />
                        ) : (
                          <Icon
                            name="outline/lock-closed"
                            className="mr-2 w-4 h-4"
                          />
                        )}
                      </span>
                      <span>
                        {this.pageType === "add" ? "Invite" : "Update"}
                      </span>
                      <span className="w-full"></span>
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </Dashboard>
    );
  }
}

export default compose<ComponentType<Props>>(
  connector,
  withRouter
)(AddEditViewUser);
